import React from "react";
import { Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import SignUpComponent from "./components/Auth/signup/components/signUpComponent";
import gfComponent from "./components/Auth/signup/components/gfComponent";
import verifyEmailComponent from "./components/Auth/signup/components/verifyEmailComponent";
import RegisterComponent from "./components/pages/register/components/registerComponent";
import LoginComponent from "./components/Auth/login/login.component";
import ForgotPasswordComponent from "./components/Auth/forgot-password/components/forgotPasswordComponent";
import OtpPasswordComponent from "./components/Auth/forgot-password/components/otpComponent";
import verifyOtpPasswordComponent from "./components/Auth/forgot-password/components/otpComponent";
import resetPassword from "./components/Auth/forgot-password/components/resetPasswordComponent";
import IndexComponent from "./components/pages/home/components/indexComponent";
import WhoAreMainPageComponent from "./components/pages/who-we-are/components/whoWeAreMainPageComponent";
import PrivacyPolicyComponent from "./common/components/privacyPolicyComponent";
import TermsConditionComponent from './common/components/termsConditionComponent';
import FAQComponent from './common/components/faqComponent';
import GiveAwayComponent from './common/components/giveAwayComponent';
import ServiceAgreementComponent from './common/components/serviceAgreementComponent';
import Layout from './Layout'
import PricingContent from "./components/pages/dealer/components/pricingComponent";
import registerComponent from "./components/Auth/register/components/registerComponent";
import setPasswordComponent from "./components/Auth/forgot-password/components/setPasswordComponent";
import StepperComponent from "./components/pages/dealer/components/stepperComponent";
import Dealerfeed from "./components/pages/administration/dealerComponent";
import dealerInventory from "./components/pages/dashboard/component/DealerFeed/dealerInventory";
import NewStepperComponent from './components/pages/dealer/components/dealerNewsubs/dealerNewSteppper'
import chooseSubscription from "./components/pages/dealer/components/dealerNewsubs/chooseSubscription";

const theme = createMuiTheme({
  palette: {
    type: "light",
  },
});


const Routes = (
  <MuiThemeProvider theme={theme}>
    <Switch>


      {/* <Route exact={true} from="/" component={IndexComponent} /> */}
      <Route path='/' exact={true}  >
        {
          () => {
            window.location.href = "https://www.watchops.com/";
            return null;
          }
        }
      </Route>
      <Route exact={true} from="/home" component={IndexComponent} />
      <Route exact={true} path="/sign-up" component={SignUpComponent} />
      <Route exact={true} path="/register" component={RegisterComponent} />
      <Route exact={true} path="/login" component={LoginComponent} />
      <Route exact={true} path="/SignUp" component={gfComponent} />
      <Route exact={true} path="/pricing" component={PricingContent} />
      <Route exact={true} path="/dealersubs" component={StepperComponent}></Route>
      {/* Comment on Jan 25 <Route exact={true} path="/subscription" component={chooseSubscription}></Route> */}
      {/*  Comment on Jan 25 <Route exact={true} path="/dealernew" component={NewStepperComponent}></Route> */}
      <Route exact={true} path="/dealerfeed/:name" component={dealerInventory}></Route>
      <Route exact={true} path="/verify-email" component={verifyEmailComponent} />
      <Route exact={true} path="/bussiness-detail" component={registerComponent} />
      <Route
        exact={true}
        path="/forgot-password"
        component={ForgotPasswordComponent}
      />
      <Route exact={true} path="/forgot-otp" component={OtpPasswordComponent} />
      <Route exact={true} path="/verify-otp" component={verifyOtpPasswordComponent} />
      <Route
        exact={true}
        path="/reset-password"
        component={resetPassword}
      />
      <Route
        exact={true}
        path="/set-password"
        component={setPasswordComponent}
      />
      <Route
        exact={true}
        path="/privacy-policy"
        component={PrivacyPolicyComponent}
      />
      <Route
        exact={true}
        path="/terms-conditions"
        component={TermsConditionComponent}
      />
      <Route
        exact={true}
        path="/service-agreement"
        component={ServiceAgreementComponent}
      />
      <Route
        exact={true}
        path="/faq"
        component={FAQComponent}
      />
      <Route
        exact={true}
        path="/giveaway"
        component={GiveAwayComponent}
      />
      <Route
        exact={true}
        path="/who-we-are"
        component={WhoAreMainPageComponent}
      />
      <Route path='/' render={(props) => <Layout {...props} />} />
    </Switch>
  </MuiThemeProvider>
);


export default Routes;


