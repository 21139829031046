import axios from "axios";
import constants from "./../../../../utils/constants";
import { getToken } from "./../../../../utils/Authentication";
import { toast } from "react-toastify";
import { PDFDocument } from 'pdf-lib';
const fileDownload = require('js-file-download');

export function individualBrowseWatch(PId) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `products/` + PId, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_INDIVIDUAL_BROWSE_WATCH_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function getBrandId(PId) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `products/detalis/model/` + PId + '/1', { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "GET_BRAND_ID",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function subscriptionsPlans() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `packages/`)
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_SUBSCRIPTION_PLAN_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function brandList() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `brands/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function caseMaterial() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `products/case_materials/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_BROWSE_WATCHES_CASE_MATERIAL_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function bandMaterial() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `products/band_materials/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_BROWSE_WATCHES_BAND_MATERIAL_DATA_SUCCESS",
            payload: response.data.data,
          });
        }
      })
      .catch((error) => { });
  };
}
export function addProduct(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "multipart/form-data",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "products",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      // toast(response.data.message, {
      //     autoClose: 5000,
      //     type: toast.TYPE.SUCCESS,
      //     position: toast.POSITION.BOTTOM_CENTER,
      // });
      // document.getElementById("add_product_form").reset();
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function addInventoryData(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "multipart/form-data",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "inventories",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        document.getElementById("add_inventory_form").reset();
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function addInventoryImage(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "multipart/form-data",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "inventories/uploads",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}



export function addMiscInventoryData(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "multipart/form-data",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "inventories/V1/add",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function countriesList() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `countries/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_COUNTRYLIST_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function statesList(id) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `/countries/` + id + `/states/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_STATESLIST_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function citiesList(CityId) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `/cities/` + CityId, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_CITIES_DATA_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function addUserData(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "users",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        document.getElementById("subscribe_signup_form").reset();
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function updateUserData(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "patch",
    url: constants.BASE_URL + "users/business",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        document.getElementById("subscribe_user_update_form").reset();
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function packagesList() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `packages/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_PACKAGES_DATA_SUCCESS",
            payload: response.data.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function activePackageList() {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    axios
      .get(constants.BASE_URL + `packages/my/active/`, { headers })
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "FETCH_ACTIVE_PACKAGES_DATA_SUCCESS",
            payload: response.data.data,
          });
          dispatch({
            type: "PACKAGES_SELECTED_SUCCESS",
            payload: response.data.data,
          });
        }
      })
      .catch((error) => { });
  };
}

export function setPackage(pkg) {
  return function (dispatch) {
    dispatch({
      type: "PACKAGES_SELECTED_SUCCESS",
      payload: pkg,
    });
  };
}

export function updateBusinessDetails(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "patch",
    url: constants.BASE_URL + "users/business",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        // return callback(null, response.data);
        this.props.setStep(2)
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function updateBillingDetails(data, callback) {
  const paymentmode = localStorage.getItem('paymentType');
  // type: paymentmode.toLowerCase() || 'monthly'
  let _data = {
    user_id: data.user_id,
    membertier_id: data.membertier_id,
    listing_addon: data.listing_addon,
    gateway: data.gateway,
    billing_country: data.billing_country,
    billing_state: data.billing_state,
    billing_city: data.billing_city,
    billing_zip: data.billing_zip,
    billing_addressline1: data.billing_addressline1,
    billing_addressline2: data.billing_addressline2,
    mode: paymentmode && paymentmode.toLowerCase() || 'monthly'
  };
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "payments/checkout",
    headers: headers,
    data: _data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function updatePaymentDetails(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "payments/submit",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }

    })
    .catch((error) => {
      console.log(error, "error")
      return callback(null, error);
    });
}

export function newsLatterList(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "newsletters",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        toast(response.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        document.getElementById("news_letter").reset();
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function searchProducts(data, callback) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: constants.BASE_URL + "products/search/" + data.searchText,
      headers: headers,
    };
    dispatch({ type: "AUTOCOMPLETE_PRODUCTS_REQUESTED" });
    axios(config)
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "AUTOCOMPLETE_PRODUCTS_SUCCESS",
            payload: response.data.data,
          });

          return callback(null, response.data);
        }
      })
      .catch((error) => {
        dispatch({ type: "AUTOCOMPLETE_PRODUCTS_FAILED", payload: error });

        return callback(error, null);
      });
  };
}

export function searchLocation(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "get",
    url: constants.BASE_URL + "inventories/search/inventory/locations/" + data.locationSearch,
    headers: headers,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function searchProductsAPI(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "get",
    url: constants.BASE_URL + "products/search/" + data.searchText,
    headers: headers,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function addChornoListing(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "inventories/listing",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response);
      }
    })
    .catch((error) => {
      console.log(error,);
    });
}

export function saveBillingDetails(data) {
  return function (dispatch) {
    dispatch({ type: "UPDATE_BILLING_DETAILS", payload: data });
  };
}

export function invoiceDashboard(data, callback) {
  return function (dispatch) {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: constants.BASE_URL + "invoices/v1/list/invoice",
      headers: headers,
      data: data
    };
    dispatch({ type: "INVOICE_DASHBORDS_LIST" });
    axios(config)
      .then((response) => {
        if (response && response.data && response.data.success === 0 && response.data.logout === true) {
          localStorage.removeItem('token');
          window.sessionStorage.removeItem("userDetails");
          localStorage.removeItem("UserBusinessDetails");
          window.location.href = '/login';
        } else {
          dispatch({
            type: "INVOICE_DASHBORDS_LIST_SUCCESS",
            payload: response.data.data,
          });
          return callback(null, response.data);
        }
      })
      .catch((error) => {
        // dispatch({ type: "INVOICE_DASHBORDS_LIST_FAILED", payload: error });
        // return callback(error, null);
      });
  };
}

export function updatePaymentStatus(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "invoices/paymentstatus/create",
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      // toast(response.data.message, {
      //     autoClose: 5000,
      //     type: toast.TYPE.SUCCESS,
      //     position: toast.POSITION.BOTTOM_CENTER,
      // });
      // document.getElementById("add_product_form").reset();
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}



export function getPaymentHistory(data, callback) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    "Content-Type": "application/json",
  };
  var config = {
    method: "get",
    url: constants.BASE_URL + "invoices/paymenthistory/" + data.invoiceId,
    headers: headers,
    data: data,
  };
  axios(config)
    .then((response) => {
      if (response && response.data && response.data.success === 0 && response.data.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        // toast(response.data.message, {
        //     autoClose: 5000,
        //     type: toast.TYPE.SUCCESS,
        //     position: toast.POSITION.BOTTOM_CENTER,
        // });
        // document.getElementById("add_product_form").reset();
        return callback(null, response.data);
      }
    })
    .catch((error) => {
      return callback(error, null);
    });
}

export function downloadInvoice(invoiceId) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    // "Content-Type": "application/json",
  };
  var config = {
    method: "get",
    url: constants.BASE_URL + "invoices/download/invoice/" + invoiceId,
    headers: headers,
    responseType: 'blob',

  };
  return axios(config)
    .then((response) => {
      if (response && response.success === 0 && response.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        // return callback(null, response.data);
        const blob = new Blob([response.data])
        // , {
        //   type:
        //     'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        // })
        const blob_url = URL.createObjectURL(blob);

        fileDownload(blob, `invoice_${invoiceId}.pdf`)
        const data = {
          status: 1,
          message: "Downloaded Successfully",
          url: blob_url
        }
        return data
      }
    })
    .catch((error) => {
      const data = {
        status: 0,
        message: "Downloaded Failed",
      }
      return data
    });
}
export async function alldownloadInvoice(payload, invoiceId) {
  const headers = {
    Authorization: `Bearer ${getToken() || ""}`,
    // "Content-Type": "application/json",
  };
  var config = {
    method: "post",
    url: constants.BASE_URL + "invoices/download/invoice/excel",
    headers: headers,
    data: payload,
    responseType: 'arraybuffer',

  };
  return axios(config)
    .then((response) => {
      if (response && response.success === 0 && response.logout === true) {
        localStorage.removeItem('token');
        window.sessionStorage.removeItem("userDetails");
        localStorage.removeItem("UserBusinessDetails");
        window.location.href = '/login';
      } else {
        // return callback(null, response.data);
        const blob = new Blob([response.data]
          , {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
          })
        const fileName = invoiceId ? `invoice_${invoiceId}.xlsx` : `invoices.xlsx`
        fileDownload(blob, fileName)
        const data = {
          status: 1,
          message: "Downloaded Successfully",

        }
        return data
      }
    })
    .catch((error) => {
      const data = {
        status: 0,
        message: "Downloaded Failed",
      }
      return data
    });
}

// export async function downloadInvoicepreview(invoiceId) {
//   const headers = {
//     Authorization: `Bearer ${getToken() || ""}`,
//     // "Content-Type": "application/json",
//   };
//   var config = {
//     method: "get",
//     url: constants.BASE_URL + "invoices/download/invoice/" + invoiceId,
//     headers: headers,
//     responseType: 'blob'
//   };
//   return axios(config)
//     .then(async (response) => {
//       if (response && response.success === 0 && response.logout === true) {
//         localStorage.removeItem('token');
//         window.sessionStorage.removeItem("userDetails");
//         localStorage.removeItem("UserBusinessDetails");
//         window.location.href = '/login';
//       }
//       else {
//         const blob = response.data;
//         const blob_url = URL.createObjectURL(blob);
//         // const pdf=fileDownload(blob, 'invoice.pdf' )
//         const data = {
//           url: blob_url,

//         }
//         return data;
//       }
//     })
//     .catch((error) => {
//       const data = {
//         status: 0,
//         message: "Invoice preview not avaliable",
//       }
//       return data
//     })
// }

export async function downloadInvoicepreview(invoiceId) {
  try {
    const headers = {
      Authorization: `Bearer ${getToken() || ""}`,
    };

    const config = {
      method: "get",
      url: `${constants.BASE_URL}invoices/download/invoice/${invoiceId}`,
      headers: headers,
      responseType: 'blob'
    };

    const response = await axios(config);
    if (response.data && response.data.success === 0 && response.data.logout === true) {
      localStorage.removeItem('token');
      window.sessionStorage.removeItem("userDetails");
      localStorage.removeItem("UserBusinessDetails");
      window.location.href = '/login';
      return;
    } else {
      const blob = response.data;
      const existingPdfBytes = await blob.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.setTitle(`invoice_${invoiceId}`);
      const pdfBytes = await pdfDoc.save();
      const updatedBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(updatedBlob);
      const data = {
        url: blobUrl,
      }
      return data;
    }
  } catch (error) {
    console.error("Error downloading invoice preview:", error);
    return {
      status: 0,
      message: "Invoice preview not available",
    };
  }
}