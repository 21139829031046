import React from "react";
import { useState, useEffect } from "react";
import {
    Grid,
    Container,
    Typography,
    FormControl,
    Paper,
    InputBase,
    InputAdornment,
    TextField,
} from "@material-ui/core";
import Menu from "@mui/material/Menu";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@mui/material/IconButton";
import DatePicker from "react-multi-date-picker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { connect } from "react-redux";
import { getActivityList } from "./actions/activityLogs";
import moment from "moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === "light"
                ? "rgb(55, 65, 81)"
                : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0",
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            "&:active": {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const useStyles = makeStyles((theme) => ({
    sortIcon: {
        fontSize: "1rem",
        verticalAlign: "middle",
        cursor: "pointer",
    },
}));

const columns = [
    { id: "Team", label: "Team Member", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 100 },
    {
        id: "event",
        label: "Event",
        minWidth: 100,
    },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ActivityLogsComponent = (props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [shippingDateValue, setShippingDateValue] = useState([]);
    const [selectedShippingDate, setSelectedShippingDate] = useState([]);
    const [inventoryId, setInventoryId] = useState("Inventory ID");
    const [invoiceId, setInvoiceId] = useState("Invoice ID");
    const [data, setData] = useState([]);

    const handleDataCallback = (response) => {
        setData(response.data);
    };

    useEffect(() => {
        const userDetails = window.sessionStorage.getItem("userDetails")
            ? JSON.parse(window.sessionStorage.getItem("userDetails"))
            : null;
        const databoardObject = {
            accountid: userDetails?.accountId,
            name: null,
        };
        if (selectedShippingDate && selectedShippingDate.length === 2) {
            databoardObject["activityDateInterval"] = {
                from: selectedShippingDate[0].format(),
                to: selectedShippingDate[1].format(),
            };
        }
        props.getActivityList(databoardObject, handleDataCallback);
    }, [selectedShippingDate]);

    useEffect(() => {
        if (shippingDateValue && shippingDateValue.length === 2) {
            setSelectedShippingDate(shippingDateValue);
        }
        if (
            shippingDateValue.length === 0 &&
            selectedShippingDate &&
            selectedShippingDate.length === 2
        ) {
            setSelectedShippingDate([]);
        }
    }, [shippingDateValue]);

    const fetchUsersGrid = (from = null) => {
        const userDetails = window.sessionStorage.getItem("userDetails")
            ? JSON.parse(window.sessionStorage.getItem("userDetails"))
            : null;

        const dashboardData = {
            accountid: userDetails?.accountId,
            name: from === "clear" ? "" : searchKeyword.trim(),
        };
        if (shippingDateValue && shippingDateValue.length === 2) {
            dashboardData["activityDateInterval"] = {
                from: shippingDateValue[0].format(),
                to: shippingDateValue[1].format(),
            };
        }
        setPage(0);
        props.getActivityList(dashboardData, handleDataCallback);
    };

    const onSearchClick = (event) => {
        setSearchKeyword(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleEnterEvent = (event) => {
        if (event.key == "Enter") {
            fetchUsersGrid();
        }
    };

    const endAdornment = () => {
        const values = searchKeyword;
        if (values) {
            return (
                <InputAdornment position="end">
                    <IconButton
                        onClick={handleClearButton}
                        onMouseDown={handleMouseDown}
                        color="primary"
                    >
                        <ClearIcon />
                    </IconButton>
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={fetchUsersGrid}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        } else {
            return (
                <InputAdornment position="end">
                    <IconButton
                        className="primary-bg color-white"
                        type="button"
                        sx={{ p: "10px" }}
                        aria-label="search"
                        onClick={fetchUsersGrid}
                    >
                        <SearchIcon />
                    </IconButton>
                </InputAdornment>
            );
        }
    };
    const handleClearButton = () => {
        setSearchKeyword("");
        fetchUsersGrid("clear");
    };

    const handleMouseDown = (event) => {
        event.preventDefault();
    };
    function CustomInput({ onFocus, value, onChange }) {
        return (
            <TextField
                id="outlined-basic"
                label="Activity Date"
                style={{ width: "100%" }}
                variant="outlined"
                onFocus={onFocus}
                placeholder="from YYYY/MM/DD to YYYY/MM/DD"
                value={value}
                onChange={onChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <CalendarMonthIcon />
                        </InputAdornment>
                    ),
                }}
            />
        );
    }

    return (
        <div className="space-bottom-30 someextrahight">
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            <b>Activity Logs</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <DatePicker
                            range
                            render={<CustomInput />}
                            value={shippingDateValue}
                            containerStyle={{ display: "block" }}
                            onChange={(dateObjects) => {
                                setShippingDateValue(dateObjects);
                            }}
                            rangeHover
                            dateSeparator=" to "
                            plugins={[<DatePanel />]}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl className="form-width-100">
                            <div style={{ marginLeft: "5px" }}>
                                <Paper
                                    component="form"
                                    sx={{ p: "2px 0px" }}
                                    style={{ height: "55px", display: "flex", width: "100%" }}
                                >
                                    <InputBase
                                        //   sx={{ ml: 1, flex: 1 }}
                                        fullWidth
                                        placeholder="Search by Name / Email"
                                        style={{
                                            flex: 1,
                                            marginLeft: "20px",
                                            marginRight: "10px",
                                        }}
                                        inputProps={{
                                            "aria-label": "Search by Name / Email",
                                        }}
                                        onChange={(e) => {
                                            onSearchClick(e);
                                        }}
                                        onKeyPress={handleEnterEvent}
                                        value={searchKeyword}
                                        endAdornment={endAdornment()}
                                    />
                                </Paper>
                            </div>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper style={{ width: "100%", overflow: "hidden" }}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <StyledTableCell key={column.id} align={column.align}>
                                                    {column.label}
                                                </StyledTableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data && data.length ? (
                                            <>
                                                {data
                                                    .slice(
                                                        page * rowsPerPage,
                                                        page * rowsPerPage + rowsPerPage
                                                    )
                                                    .map((row) => {
                                                        return (
                                                            <TableRow
                                                                hover
                                                                role="checkbox"
                                                                tabIndex={-1}
                                                                key={row.code}
                                                            >
                                                                {columns.map((column) => {
                                                                    const value = row;
                                                                    return (
                                                                        <>
                                                                            {column.id === "Team" && (
                                                                                <>
                                                                                    <StyledTableCell>
                                                                                        <b>{row.username}</b> <br></br>
                                                                                        {row.email}
                                                                                    </StyledTableCell>
                                                                                </>
                                                                            )}
                                                                            {column.id === "date" && (
                                                                                <>
                                                                                    <StyledTableCell>
                                                                                        {row.createdon
                                                                                            ? moment(row.createdon).format(
                                                                                                "MM/DD/YYYY hh:mm:ss"
                                                                                            )
                                                                                            : ""}
                                                                                    </StyledTableCell>
                                                                                </>
                                                                            )}
                                                                            {column.id === "event" && (
                                                                                <>
                                                                                    {value.eventinventoryid &&
                                                                                        value.eventinvoiceid === null && (
                                                                                            <StyledTableCell>
                                                                                                {row.events} -{" "}
                                                                                                <b>
                                                                                                    {inventoryId}{" "}
                                                                                                    {row.eventinventoryid}
                                                                                                </b>
                                                                                            </StyledTableCell>
                                                                                        )}
                                                                                    {value.eventinvoiceid &&
                                                                                        value.eventinventoryid === null && (
                                                                                            <StyledTableCell>
                                                                                                {row.events} -{" "}
                                                                                                <b>
                                                                                                    {invoiceId} {row.eventinvoiceid}
                                                                                                </b>
                                                                                            </StyledTableCell>
                                                                                        )}
                                                                                    {value.eventinvoiceid &&
                                                                                        value.eventinventoryid && (
                                                                                            <StyledTableCell>
                                                                                                {row.events} -{" "}
                                                                                                <b>
                                                                                                    {invoiceId} {row.eventinvoiceid}{" "}
                                                                                                    and {inventoryId}{" "}
                                                                                                    {row.eventinventoryid}
                                                                                                </b>
                                                                                            </StyledTableCell>
                                                                                        )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </>
                                        ) : (
                                            <TableCell colspan={12} style={{ textAlign: "center" }}>
                                                <Typography variant="h6" className="color-BCBCBC">
                                                    No Records Found
                                                </Typography>
                                            </TableCell>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={data.length || 0}
                                SelectProps={{
                                    native: true,
                                }}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.Admin,
});

const mapDispatchToProps = (dispatch) => {
    return {
        getActivityList: (data, callback) =>
            dispatch(getActivityList(data, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActivityLogsComponent);
