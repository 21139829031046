import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import validator from "validator";
import {
  Container,
  Divider,
  Grid,
  Card,
  Paper,
  Input,
  Typography,
  form,
  TextField,
  Switch,
  FormHelperText,
  Button,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  DialogContentText,
  Box,
} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import profile from "../../../../assests/images/profile/Group 1854.png";
import edit from "../../../../assests/images/profile/Group 1770.png";
import Email from "../../../../assests/images/profile/Path 2038.png";
import phone from "../../../../assests/images/profile/Path 2039.png";
import map from "../../../../assests/images/profile/Group 1771.png";
import {
  countriesList,
  getuser,
  addProfileData,
  addProfileImage,
  removeAccount,
  getBusinessInfo,
} from "../actions/dashboardAction";
import getUserdetailsforsubscrption from "../../../pages/dashboard/actions/dashboardaapi";
import {
  getComponentActionList,
  isActionAllowed,
} from "../../../../common/components/Helper/Utils";
import { activePackageList } from "./../../dealer/actions/dealerAction";
import { data } from "jquery";
import profileimg from "../../../../../src/assests/images/profile/profile.png";
import { SpinnerRoundFilled } from "spinners-react";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { addDays, format } from "date-fns";
import RemoveAccountModal from "./RemoveAccountModal";
import ContentWrapper from "../../../../common/components/contentWrapper";
import LoadingSpinner from "@mui/material/CircularProgress";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// import { trim } from "lodash";
class ProfileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      // removeReason: "",
      // RemoveReasonErrortext: "",
      edit: "",
      editable: false,
      editable1: false,
      disbleeditbtn: true,
      disbleeditbtn1: true,
      disbledntn: false,
      disbledntn1: false,
      isDisabled: true,
      isDisabled1: true,
      isLoading: false,
      firstName: "",
      businessName: "",
      lastName: "",
      emailId: "",
      phoneNumber: "",
      DOB: "",
      gender: "",
      countryCode: "",
      statesListData: [],
      country: "",
      city: "",
      statename: "",
      zipCode: "",
      profilePic: "",
      UploadPic: "",
      profilePicLocal: "/static/media/Group 1854.82be9dbe.png",
      addressLine1: "",
      addressLine2: "",
      bankName: "",
      accountNumber: "",
      achRouting: "",
      wireRouting: "",
      swiftCode: "",
      businessStreetAddress: "",
      onFirstNameErrorText: "",
      onLastNameErrorText: "",
      onEmailIdErrorText: "",
      onPhoneNumberErrorText: "",
      onCountryCodeErrorText: "",
      onDobErrorText: "",
      onGenderErrorText: "",
      onCountryErrorText: "",
      onCityErrorText: "",
      onStateErrorText: "",
      onZipCodeErrorText: "",
      onBusinessNameErrorText: "",
      onMessageErrorText: "",
      onAddressErrorText: "",
      onUploadPicErrorText: "",
      ontaxIdErrorText: "",
      onSwiftCodeErrorText: "",
      onWireRoutingErrorText: "",
      onAccountNumberErrorText: "",
      onAccountNameErrorText: "",
      onBankNameErrorText: "",
      onAchRoutingErrorText: "",
      loaded: false,
      countryListData: [],
      statesListData: [],
      UploadPic1: "",
      onUploadLogoErrorText: "",
      uploadLogo: "",
      isLogoValid: "",
      files: "",
      accessData: [],
      accountId: "",
      tax_id: "",
      accountName: "",
      log_url: "",
      src: null,
      cropResult: null,
    };
    const { history, currentUser, logoutAction } = props;

    this.cropperRef = React.createRef();

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // this.onChangeOfRemoveReason = this.onChangeOfRemoveReason.bind(this);

    this.onChangeOfFirstName = this.onChangeOfFirstName.bind(this);
    this.onChangeOfLastName = this.onChangeOfLastName.bind(this);
    this.onChangeOfPhoneNumber = this.onChangeOfPhoneNumber.bind(this);
    this.onChangeOfEmailId = this.onChangeOfEmailId.bind(this);
    this.onChangeOfCountry = this.onChangeOfCountry.bind(this);
    this.onChangeOfCity = this.onChangeOfCity.bind(this);
    this.onChangeOfState = this.onChangeOfState.bind(this);
    // this.onChangeOfZipcode = this.onChangeOfZipcode.bind(this);
    this.updatepackage = this.updatepackage.bind(this);
    this.onChangeOfAddressLine1 = this.onChangeOfAddressLine1.bind(this);
    this.onChangeOfAddressLine2 = this.onChangeOfAddressLine2.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UploadPhoto = this.UploadPhoto.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.getcountryList = this.getcountryList.bind(this);
    this.getStatesList = this.getStatesList.bind(this);
    this.onChangeOfbankName = this.onChangeOfbankName.bind(this);
    this.onChangeOfaccountNumber = this.onChangeOfaccountNumber.bind(this);
    this.onChangeOfaccountName = this.onChangeOfaccountName.bind(this);
    this.onChangeOfachRouting = this.onChangeOfachRouting.bind(this);
    this.onChangeOfwireRouting = this.onChangeOfwireRouting.bind(this);
    this.onChangeOfswiftCode = this.onChangeOfswiftCode.bind(this);
    this.onChangeOfTaxId = this.onChangeOfTaxId.bind(this);
    this.submitForm1 = this.submitForm1.bind(this);
    this.validateInput1 = this.validateInput1.bind(this);
  }
  componentWillMount() { }
  componentDidMount() {
    const accessData = getComponentActionList("my_profile");
    const userDetails = window.sessionStorage.getItem("userDetails");
    const UserId = JSON.parse(userDetails).userId;
    const userDetails1 = window.sessionStorage.getItem("userDetails")
      ? JSON.parse(window.sessionStorage.getItem("userDetails"))
      : null;
    const accountId = userDetails1.accountId;
    this.setState({
      accountId: accountId,
    });
    this.setState({ accessData: accessData });
    // localStorage.setItem("UserId", this.props.currentUser?.userId);
    setTimeout(() => {
      this.setState({ loaded: true });
    }, 500);
    this.props.activePackageList();
    this.getcountryList();
    if (this.props.currentUser) {
      this.props.getuser(UserId);
    }
  }

  cropImage = (e) => {
    e.preventDefault();
    if (this.cropperRef.current) {
      const cropper = this.cropperRef.current.cropper;
      cropper.getCroppedCanvas().toBlob((blob) => {
        if (blob) {
          const url = URL?.createObjectURL(blob) || "";
          this.setState({
            cropResult: url,
            uploadLogo: url,
          });
          const file = new File(
            [blob],
            `${JSON.parse(sessionStorage?.userDetails)?.accountName + ".png"}`,
            {
              type: blob.type,
              lastModified: Date.now(),
            }
          );
          this.setState({
            files: file,
          });
        } else {
          console.log("Failed to craete Blob..");
        }
      });
    }
  };

  handleOpen() {
    this.setState({
      open: true,
    });
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }
  //   onChangeOfRemoveReason = () => {

  //     const removeuser= localStorage.getItem('UserId');
  // const reasonval=this.state.removeReason
  //     if (this.state.removeReason !== '') {

  //       const data = {

  //         'deletedReason': this.state.removeReason,

  //       }
  //       removeAccount(removeuser, data, (success, error) => {
  //         if (success) {
  //           this.setState({
  //             open:false
  //           })
  //         }
  //       })
  //     } else {
  //       this.setState({ RemoveReasonErrortext: 'Remove reason should not be blank.' })
  //     }
  //   }

  getcountryList() {
    getUserdetailsforsubscrption.getAllCountryList().then((res) => {
      this.setState({
        countryListData: res.data.data,
      });
    });
  }

  getStatesList(country) {
    getUserdetailsforsubscrption.getAllStateList(country).then((res) => {
      this.setState({
        statesListData: res.data.data,
      });
    });
  }

  componentWillReceiveProps(newProps) {
    const userDetails = window.sessionStorage.getItem("userDetails");
    const UserId = JSON.parse(userDetails).userId;
    const userInfoData = newProps.userInfo.data;
    this.setState({
      ...this.state,
      userId: userInfoData && userInfoData.userid,
      firstName: userInfoData && userInfoData.firstname,
      lastName: userInfoData && userInfoData.lastname,
      businessName: userInfoData && userInfoData.businessname,
      phoneNumber: userInfoData && userInfoData.phone,
      emailId: userInfoData && userInfoData.email,
      countryCode: userInfoData && userInfoData.countrycode,
      statename: userInfoData && userInfoData.accountstate,
      gender: userInfoData && userInfoData.gender,
      country: userInfoData && userInfoData.accountcountry,
      city: userInfoData && userInfoData.accountcity,
      zipCode: userInfoData && userInfoData.accountzip,
      addressLine1: userInfoData && userInfoData.accountaddressline1,
      addressLine2: userInfoData && userInfoData.accountaddressline2,
      bankName: userInfoData && userInfoData.bankname,
      accountNumber: userInfoData && userInfoData.accountnumber,
      accountName: userInfoData && userInfoData.accountname,
      achRouting: userInfoData && userInfoData.ach_routing,
      wireRouting: userInfoData && userInfoData.wire_routing,
      swiftCode: userInfoData && userInfoData.swiftcode,
      UploadPic: userInfoData && userInfoData.profilepic,
      uploadLogo: userInfoData && userInfoData.logo_url,
      tax_id: userInfoData && userInfoData.tax_id,
    });

    if (newProps.currentUser && !this.state.currentUser) {
      this.setState({
        ...this.state,
        currentUser: newProps.currentUser,
      });
      this.props.getuser(UserId);
    }
    this.getcountryList();
    if (userInfoData && userInfoData.accountcountry) {
      this.getStatesList(
        userInfoData && userInfoData.accountcountry
          ? userInfoData.accountcountry
          : null
      );
    }
  }

  UploadPhoto = ({ target }) => {
    const userDetails = window.sessionStorage.getItem("userDetails");
    const UserId = JSON.parse(userDetails).userId;
    const imageFile = target.files[0];
    this.setState({
      onUploadPicErrorText: "",
      uploadLogo: false,
      isValid: true,
    });
    if (!imageFile) {
      this.setState({
        onUploadPicErrorText: "Please select image.",
        isValid: false,
      });
      return false;
    }

    if (!imageFile.name.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadPicErrorText:
          "Please select only jpg,png or jpeg format images",
        isValid: false,
      });
      return false;
    }
    var file = target.files[0];
    if (file === "") {
      this.setState({
        onUploadPicErrorText: "Please Upload Photo",
        isValid: false,
      });
    } else {
      this.setState({
        UploadPic1: URL.createObjectURL(file),
        file: file,
        isValid: true,
      });
    }
    let fd = new FormData();
    fd.set("user_id", UserId);
    fd.set("image", file);
    addProfileImage(fd, (error, res) => {
      this.props.getuser(UserId);
    });
  };

  onChangeOfFirstName = (event) => {
    let FirstNameVal = event.target.value;
    if (!FirstNameVal) {
      this.setState({
        ...this.state,
        onFirstNameErrorText: "Enter correct First Name",
        firstName: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onFirstNameErrorText: "",
        firstName: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfBusinessName = (event) => {
    let BusinesstNameVal = event.target.value;
    if (!BusinesstNameVal) {
      this.setState({
        ...this.state,
        onBusinessNameErrorText: "Enter correct Business Name",
        businessName: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onBusinessNameErrorText: "",
        businessName: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfLastName = (event) => {
    let LastNameVal = event.target.value;
    if (!LastNameVal) {
      this.setState({
        ...this.state,
        onLastNameErrorText: "Enter correct Last Name",
        lastName: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onLastNameErrorText: "",
        lastName: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfGender = (event) => {
    let GenderVal = event.target.value;
    if (!GenderVal) {
      this.setState({
        ...this.state,
        onGenderErrorText: "Select Gender",
        gender: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onGenderErrorText: "",
        gender: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfEmailId = (event) => {
    let emailIdVal = validator.isEmail(event.target.value);
    if (!emailIdVal) {
      this.setState({
        ...this.state,
        onEmailIdErrorText: "Enter correct Email Id",
        emailId: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onEmailIdErrorText: "",
        emailId: event.target.value,
        isValid: true,
      });
    }
  };

  // onChangeOfCountryCode = (event) => {
  //   const regex = "^(?:[+\d].*\d|\d)$";
  //   var sign = "+";
  //   let countryCodeVal =  event.target.value;
  //   let countryCode = countryCodeVal.match(regex);
  //  if (event.target.value >=1 && event.target.value <= 4) {
  // // let countryCodeVal = validator.isNumeric(event.target.value);
  //   if ( !countryCode) {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "Enter minimum 3 characters for country code",
  //       isValid: false
  //     })
  //   }
  //   else {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "",
  //       countryCode: countryCodeVal,
  //       isValid: true
  //     })
  //   }
  // }
  //   else {
  //     this.setState({
  //       ...this.state,
  //       countryCodeError: "",
  //       countryCode: this.state.sign + event.target.value,
  //       isValid: true
  //     })
  //   }
  // }
  onChangeOfCountryCode = (event) => {
    let CountryCodeVal = validator.isNumeric(event.target.value);
    if (!CountryCodeVal) {
      this.setState({
        ...this.state,
        onCountryCodeErrorText: "Enter correct Country Code",
        countryCode: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onCountryCodeErrorText: "",
        countryCode: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfTaxId = (event) => {
    const taxIdVal = event.target.value.replace(/\D/g, "");
    if (taxIdVal.length >= 9) {
      const TaxId = `${taxIdVal.slice(0, 3)}-${taxIdVal.slice(
        3,
        5
      )}-${taxIdVal.slice(5, 9)}`;

      this.setState({
        ...this.state,
        ontaxIdErrorText: "",
        tax_id: TaxId,
        isValid: true,
      });
    } else {
      this.setState({
        ...this.state,
        ontaxIdErrorText: "Enter correct Tax Id",
        tax_id: taxIdVal,
        isValid: false,
      });
    }
  };

  onChangeOfPhoneNumber = (event) => {
    // let PhoneNumberVal = validator.isMobilePhone(event.target.value);
    const regex = "^\\d{10}$";
    let PhoneNumberVal = event.target.value;
    if (event.target.value >= 10) {
      let phonemuber = PhoneNumberVal.match(regex);
      if (!phonemuber) {
        this.setState({
          ...this.state,
          onPhoneNumberErrorText: "Enter correct Phone Number",
          phoneNumber: event.target.value,
          isValid: false,
        });
      } else {
        this.setState({
          ...this.state,
          onPhoneNumberErrorText: "",
          phoneNumber: event.target.value,
          isValid: true,
        });
      }
    } else {
      this.setState({
        ...this.state,
        onPhoneNumberErrorText: "",
        phoneNumber: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfAddressLine1 = (event) => {
    let AddressLine1Val = event.target.value;
    if (!AddressLine1Val) {
      this.setState({
        ...this.state,
        onAddressLine1ErrorText: "Enter correct Address",
        addressLine1: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onAddressLine1ErrorText: "",
        addressLine1: event.target.value,
        isValid: true,
      });
    }
  };
  onChangeOfAddressLine2 = (event) => {
    let AddressLine2Val = event.target.value;
    if (!AddressLine2Val) {
      this.setState({
        ...this.state,
        onAddressLine2ErrorText: "Enter correct Address",
        addressLine2: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onAddressLine2ErrorText: "",
        addressLine2: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfbankName = (event) => {
    let BankNameVal = event.target.value;
    if (!BankNameVal) {
      this.setState({
        ...this.state,
        onBankNameErrorText: "Enter correct BankName",
        bankName: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onBankNameErrorText: "",
        bankName: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfaccountNumber = (event) => {
    let accountNumberVal = event.target.value;
    if (!accountNumberVal) {
      this.setState({
        ...this.state,
        onAccountNumberErrorText: "Enter correct Account Number",
        accountNumber: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onAccountNumberErrorText: "",
        accountNumber: event.target.value,
        isValid: true,
      });
    }
  };
  onChangeOfaccountName = (event) => {
    let accountNameVal = event.target.value;
    if (!accountNameVal) {
      this.setState({
        ...this.state,
        onAccountNameErrorText: "Enter correct Account Name",
        accountName: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onAccountNameErrorText: "",
        accountName: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfachRouting = (event) => {
    let achRoutingVal = event.target.value;
    if (!achRoutingVal) {
      this.setState({
        ...this.state,
        onAchRoutingErrorText: "Enter correct ACH Routing",
        achRouting: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onAchRoutingErrorText: "",
        achRouting: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfwireRouting = (event) => {
    let wireRoutingVal = event.target.value;
    if (!wireRoutingVal) {
      this.setState({
        ...this.state,
        onWireRoutingErrorText: "Enter correct Wire Routing",
        wireRouting: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onWireRoutingErrorText: "",
        wireRouting: event.target.value,
        isValid: true,
      });
    }
  };

  onChangeOfswiftCode = (event) => {
    let swiftCodeVal = event.target.value;
    if (!swiftCodeVal) {
      this.setState({
        ...this.state,
        onswiftCodeErrorText: "Enter correct Wire Routing",
        swiftCode: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onswiftCodeErrorText: "",
        swiftCode: event.target.value,
        isValid: true,
      });
    }
  };
  onChangeOfCountry(event) {
    this.setState({
      country: event.target.value,
    });
    getUserdetailsforsubscrption
      .getAllStateList(event.target.value)
      .then((res) => {
        this.setState({
          statesListData: res.data.data,
        });
      });
    // let CountryVal = event.target.value;
    // if (!CountryVal) {
    //   this.setState({
    //     ...this.state,
    //     onCountryErrorText: "Enter correct Country",
    //     country: event.target.value,
    //     isValid: false
    //   })
    // }
    // else {
    //   this.setState({
    //     ...this.state,
    //     onCountryErrorText: "",
    //     country: event.target.value,
    //     isValid: true
    //   })
    // }
  }

  onChangeOfCity = (event) => {
    let CityVal = event.target.value;
    if (!CityVal) {
      this.setState({
        ...this.state,
        onCityErrorText: "Enter correct City",
        city: event.target.value,
        isValid: false,
      });
    } else {
      this.setState({
        ...this.state,
        onCityErrorText: "",
        city: event.target.value,
        isValid: true,
      });
    }
  };

  // onChangeOfState = (event) => {
  //   let StateVal = event.target.value;
  //   if (!StateVal) {
  //     this.setState({
  //       ...this.state,
  //       onStateErrorText: "Enter correct State",
  //       state: event.target.value,
  //       isValid: false
  //     })
  //   }
  //   else {
  //     this.setState({
  //       ...this.state,
  //       onStateErrorText: "",
  //       state: event.target.value,
  //       isValid: true
  //     })
  //   }
  // }

  onChangeOfState = (event) => {
    let stateVal = event.target.value;
    if (stateVal === "") {
      this.setState({
        stateErrorText: "Enter State",
        isValid: false,
        statename: event.target.value,
      });
    } else {
      this.setState({
        stateErrorText: "",
        statename: event.target.value,
        isValid: true,
      });
    }
  };

  getstatebycountyId(countryID) {
    getUserdetailsforsubscrption.getAllStateList(countryID).then((res) => {
      this.setState({
        statesListData: res.data.data,
      });
    });
  }

  onChangeOfZipCode = (event) => {
    if (event.target.value !== "") {
      let zipcodeVal = validator.isNumeric(event.target.value);
      if (!zipcodeVal) {
        this.setState({
          ...this.state,
          onZipCodeErrorText: "Enter correct Zip Code",
          isValid: false,
        });
      } else {
        this.setState({
          ...this.state,
          onZipCodeErrorText: "",
          zipCode: event.target.value,
          isValid: true,
        });
      }
    } else {
      this.setState({
        ...this.state,
        onZipCodeErrorText: "",
        zipCode: event.target.value,
        isValid: true,
      });
    }
  };

  handleSubmit = () => {
    this.setState({
      isDisabled: false,
      editable: true,
      disbledntn: true,
      disbleeditbtn: false,
    });
  };
  handleSubmit1 = () => {
    this.setState({
      isDisabled1: false,
      editable1: true,
      disbledntn1: true,
      disbleeditbtn1: false,
    });
  };
  validateInput1() {
    // let onAddressLine2ErrorText = '';
    //  let onGenderErrorText = '';
    let onBankNameErrorText = "";
    let onAccountNumberErrorText = "";
    let onAccountNameErrorText = "";
    let onAchRoutingErrorText = "";
    let onWireRoutingErrorText = "";
    let onSwiftCodeErrorText = "";
    let onUploadPicErrorText = "";
    let ontaxIdErrorText = "";

    let isValid = true;

    // if (this.state.tax_id === null) {
    //   ontaxIdErrorText = 'Enter Correct Tax Id';
    //   isValid = false;
    // }
    if (this.state.bankName === null) {
      onBankNameErrorText = "Enter Bank Name";
      isValid = false;
    }
    if (this.state.accountNumber === null) {
      onAccountNumberErrorText = "Enter Account Number";
      isValid = false;
    }
    if (this.state.accountName === null) {
      onAccountNameErrorText = "Enter Account Name";
      isValid = false;
    }
    if (this.state.achRouting === null) {
      onAchRoutingErrorText = "Enter ACH Routing";
      isValid = false;
    }
    if (this.state.wireRouting === null) {
      onWireRoutingErrorText = "Enter Wire Routing";
      isValid = false;
    }
    if (this.state.swiftCode === null) {
      onSwiftCodeErrorText = "Enter Swift Code";
      isValid = false;
    }
    this.setState({
      ...this.state,
      onBankNameErrorText,
      onAccountNumberErrorText,
      onAccountNameErrorText,
      onAchRoutingErrorText,
      onWireRoutingErrorText,
      onSwiftCodeErrorText,
      onUploadPicErrorText,
      ontaxIdErrorText,
    });
    return isValid;
  }

  validateInput() {
    let onAddressLine1ErrorText = "";
    // let onAddressLine2ErrorText = '';
    //  let onGenderErrorText = '';
    let onAddressErrorText = "";
    let onBusinessNameErrorText = "";
    let onFirstNameErrorText = "";
    let onEmailIdErrorText = "";
    let onPhoneNumberErrorText = "";
    let onCountryErrorText = "";
    let onCityErrorText = "";
    let onStateErrorText = "";
    let onZipCodeErrorText = "";
    let onLastNameErrorText = "";
    let onCountryCodeErrorText = "";
    let onUploadPicErrorText = "";

    let isValid = true;
    if (this.state.uploadLogo === "") {
      onUploadPicErrorText = "Please Upload Pic";
      isValid = false;
    }
    if (this.state.firstName === null) {
      onFirstNameErrorText = "Enter Correct Full Name";
      isValid = false;
    }
    // if (this.state.gender === null) {
    //   onGenderErrorText = 'Select Gender';
    //   isValid = false;
    // }
    // if (this.state.businessName == null) {
    //   onBusinessNameErrorText = 'Enter Correct Business Name';
    //   isValid = false;
    // }
    if (this.state.phoneNumber === null || this.state.phoneNumber.length <= 9) {
      onPhoneNumberErrorText = "Enter Correct Phone Number";
      isValid = false;
    }
    // if (this.state.countryCode === null) {
    //   onCountryCodeErrorText = 'Enter Correct Country Code';
    //   isValid = false;
    // }

    // if (this.state.country === null) {
    //   onCountryErrorText = 'Please Select Country';
    //   isValid = false;
    // }
    // if (this.state.addressLine1 === null) {
    //   onAddressLine1ErrorText = 'Please Enter Address';
    //   isValid = false;
    // }
    // if (this.state.addressLine2 === null) {
    //   onAddressLine2ErrorText = 'Please Enter Address';
    //   isValid = false;
    // }
    // if (this.state.city === null) {
    //   onCityErrorText = 'Enter Correct City';
    //   isValid = false;
    // }
    // if (this.state.state === null) {
    //   onStateErrorText = 'Please Select State';
    //   isValid = false;
    // }
    // if (this.state?.zipCode?.length < 5) {
    //   onZipCodeErrorText = 'Enter Correct Zip Code';
    //   isValid = false;
    // }
    if (this.state.lastName === null) {
      onLastNameErrorText = "Enter Last Name";
      isValid = false;
    }
    if (this.state.emailId === null) {
      onEmailIdErrorText = "Enter E-mail";
      isValid = false;
    }

    this.setState({
      ...this.state,
      onBusinessNameErrorText,
      // onGenderErrorText,
      onAddressLine1ErrorText,
      // onAddressLine2ErrorText,
      onFirstNameErrorText,
      onLastNameErrorText,
      onEmailIdErrorText,
      onPhoneNumberErrorText,
      onAddressErrorText,
      onCountryErrorText,
      onCityErrorText,
      onStateErrorText,
      onZipCodeErrorText,
      onCountryCodeErrorText,
      onUploadPicErrorText,
    });
    return isValid;
  }
  updatepackage() {
    this.props.history.push("/dealersubscribtions");
    // history.push("/dealersubscribtions")
    // window.location.href = "/dealersubscribtions/"
  }
  submitForm1(event) {
    if (this.validateInput1()) {
      const userDetails = window.sessionStorage.getItem("userDetails")
        ? JSON.parse(window.sessionStorage.getItem("userDetails"))
        : null;
      // const accountId = JSON.stringify(userDetails).accountId;
      //   let qs = require('qs');
      let data1 = {
        businessname: this.state.businessName,
        accountnumber: this.state.accountNumber,
        accountname: this.state.accountName,
        ach_routing: this.state.achRouting,
        wire_routing: this.state.wireRouting,
        swiftcode: this.state.swiftCode,
        bankname: this.state.bankName,
        accountid: userDetails ? userDetails.accountId : null,
        tax_id: this.state.tax_id,
        state: this.state.statename,
        country: this.state.country,
        addressline1: this.state.addressLine1,
        addressline2: this.state.addressLine2,
        city: this.state.city,
        zip: this.state.zipCode,
      };
      if (!data1.addressline1) {
        delete data1.addressline1;
      }
      if (!data1.addressline2) {
        delete data1.addressline2;
      }
      if (!data1.city) {
        delete data1.city;
      }
      if (!data1.zipCode) {
        delete data1.zipCode;
      }
      let fd = new FormData();
      if (this.state.files) {
        fd.set("files", this.state.files);
      } else if (this.state.uploadLogo) {
        fd.set("logo_url", this.state.uploadLogo);
      }
      for (const key in data1) {
        fd.set(key, data1[key]);
      }
      getBusinessInfo(fd, (error, res) => {
        this.setState({
          disbledntn1: false,
          disbleeditbtn1: true,
          editable1: false,
          isDisabled1: true,
        });
      });
    }
  }

  submitForm(event) {
    if (this.validateInput()) {
      //   let qs = require('qs');
      let data = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        business_name: this.state.businessName,
        // "state": this.state.statename,
        // "country": this.state.country,
        // "address_line1": this.state.addressLine1,
        // "address_line2": this.state.addressLine2,
        // "city": this.state.city,
        // "zip": this.state.zipCode,
        gender: this.state.gender,
        country_code: this.state.countryCode,
        phone: this.state.phoneNumber,
        email: this.state.emailId,
        // "tax_id": this.state.tax_id
      };

      let fd = new FormData();
      fd.set("files", this.state.files);
      for (const key in data) {
        fd.set(key, data[key]);
      }
      addProfileData(fd, (error, res) => {
        this.setState({
          disbledntn: false,
          disbleeditbtn: true,
          editable: false,
          isDisabled: true,
        });
      });
    }
  }

  uploadLogo = (e) => {
    const target = e.target;
    const imageFile = target.files[0];
    this.setState({ onUploadLogoErrorText: "", isLogoValid: true });
    if (!imageFile?.name?.match(/\.(jpg|JPG|png|PNG|jpeg|JPEG)$/)) {
      this.setState({
        onUploadLogoErrorText:
          "Please select only jpg,png or jpeg format images",
        isLogoValid: false,
      });
      return false;
    } else {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.setState({ src: reader.result, uploadLogo: reader.result });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  renderProfileDetails() {
    // const futureDate = addDays(new Date(), 30); // Add 30 days to current date
    // const futureDateString = format(futureDate, ' d,MMMM, yyyy'); // Format future date as string

    const userDetails = window.sessionStorage.getItem("userDetails");
    const UserId = JSON.parse(userDetails).userId;
    const CountryList = this.props.countryListInfo.data;
    const userInfoData = this.props.userInfo.data;
    const activePackageData = this.props.activePackagesListInfo;
    let convertedState,
      convertedCountry = "";
    convertedState =
      this.state.statename &&
      this.state.statesListData &&
      this.state.statesListData.find((item) => {
        return item.id === Number(this.state.statename);
      });
    convertedCountry =
      this.state.countryListData &&
      this.state.countryListData.find((item) => {
        return item.id === Number(this.state.country);
      });
    const address =
      (this.state.addressLine1 ? this.state.addressLine1 + ", " : "") +
      (convertedState ? convertedState.name + ", " : "") +
      (convertedCountry ? convertedCountry.name + ", " : "") +
      (this.state.zipCode ? this.state.zipCode : "");

    return (
      <div
        style={{ height: "auto", margin: "20px 30px 30px 30px" }}
        className="space-bottom-30 someextrahight"
      >
        {this.state.loaded == false ? (
          <div class="d-spinner">
            <SpinnerRoundFilled
              size={51}
              thickness={125}
              speed={80}
              color="rgba(57, 107, 172, 1)"
              className="spp"
              enabled={this.state.loaded == false}
            />{" "}
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <Paper>
                <div>
                  <div className="space-bottom-20">
                    <Typography
                      variant="h5"
                      align="center"
                      className="color-454545"
                    >
                      <b>My Profile</b>
                    </Typography>
                  </div>

                  <div className="profile-pic" style={{ position: "relative" }}>
                    <input
                      accept="image/*"
                      className="input-upload"
                      id="icon-button-file2"
                      type="file"
                      onChange={this.UploadPhoto}
                    />

                    <label htmlFor="icon-button-file2">
                      <div>
                        <EditIcon
                          onChange={this.UploadPhoto}
                          style={{
                            float: "right",
                            marginRight: "5px",
                            top: "-31px",
                            position: "absolute",
                            right: " 6px",
                            cursor: "pointer",
                          }}
                        />
                        {this.state.UploadPic ? (
                          <img
                            src={
                              this.state.UploadPic
                                ? this.state.UploadPic
                                : this.state.UploadPic1
                            }
                            alt="watchimage"
                            className="img-responsive"
                            style={{
                              width: "140px",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <img
                            src={profileimg}
                            alt="watchimage"
                            className="img-responsive"
                            style={{
                              width: "140px",
                              height: "140px",
                              backgroundSize: "cover",
                              borderRadius: "50%",
                              margin: "auto",
                              cursor: "pointer",
                            }}
                          />
                        )}

                        {/* <img src={'https://api.watchops.com/images/users/' + this.state.profilePic ? 'https://api.watchops.com/images/users/' + this.state.profilePic : this.state.profilePicLocal} alt="watchimage" className="img-responsive1" style={{ width: "140px", margin: 'auto' }} /> */}
                        {/* <img src={this.state.profilePicLocal} alt="watchimage" className="img-responsive1" style={{ width: "140px", margin: 'auto' }} /> */}
                      </div>
                    </label>
                    <FormHelperText className="error-o">
                      {this.state.profilePicErrorText}
                    </FormHelperText>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <div className="profile-Details space-bottom-20">
                    <Typography
                      variant="h5"
                      className="color-222426 text-transform"
                    >
                      <b>
                        {this.state.userId
                          ? this.state.firstName + " " + this.state.lastName
                          : "LOGIN"}
                      </b>
                    </Typography>
                    {/* <Typography variant="small" className="color-454545">Joined 2nd Nov 2020</Typography> */}
                  </div>
                  <Divider />
                  <div className="userdetails">
                    <ul>
                      <li>
                        <div
                          className="color-555555"
                          style={{ wordBreak: "break-word" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={1} md={1}>
                              <img src={Email} alt="" width="14px" />
                            </Grid>
                            <Grid item xs={11} md={9}>
                              &nbsp;&nbsp;
                              {this.state.emailId}
                            </Grid>
                          </Grid>
                        </div>
                      </li>
                      <li>
                        <div
                          className="color-555555"
                          style={{ wordBreak: "break-word" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={1} md={1}>
                              <img src={phone} alt="" width="14px" />
                            </Grid>
                            <Grid item xs={10} md={9}>
                              &nbsp;&nbsp;
                              {this.state.phoneNumber}
                            </Grid>
                            <Grid item xs={1} md={2}></Grid>
                          </Grid>
                        </div>
                      </li>
                      <li>
                        {/* <div className="color-555555">

                          <Grid container spacing={2}>
                            <Grid item xs={1} md={1}>
                              <img src={map} alt="" width="10px" />
                            </Grid>
                            <Grid item xs={10} md={9}>
                              <div style={{ paddingLeft: "4px", textIndent: "", wordBreak: 'break-word' }}>
                                {address}
                              </div>
                            </Grid>
                            <Grid item xs={1} md={2}>
                            </Grid>
                          </Grid>
                        </div> */}
                      </li>
                    </ul>

                    {
                      // isActionAllowed
                      isActionAllowed(
                        this.state.accessData,
                        "delete_account"
                      ) && (
                        <Grid container justifyContent="center">
                          <Grid
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "20px",
                            }}
                          >
                            <Button
                              onClick={this.handleOpen}
                              variant="contained"
                              size="small"
                              color="primary"
                              className=" float-right primary-bg color-white text-center1"
                            >
                              Delete Account
                            </Button>
                          </Grid>
                        </Grid>
                      )
                    }
                    <RemoveAccountModal
                      open={this.state.open}
                      handleClose={this.handleClose}
                    />

                    {/* <Dialog
                      open={this.state.open}
                      onClose={this.handleClose}
                  
                    >
                      <DialogTitle >
                        {"Remove Account"}
                        <IconButton onClick={this.handleClose} style={{ float: "right" }}>
                          <CloseIcon />
                        </IconButton>
                      </DialogTitle>
                      <DialogContent>
                       
                        <DialogContentText
                        >
                          <Typography  variant="caption">If you continue ,your profile and account details will be deleted
                          on <b>{futureDateString}</b> .You won't be visible on Watchops
                          between now and then .If you change Your mind you can log back in
                          before the deletion date and choose to keep your account.
                          </Typography>
                        </DialogContentText>

                        <DialogContentText>
                            <Typography  variant="caption">Why do you want to delete Watchops? </Typography>
                        </DialogContentText>

                        <Box sx={{
                          '& .MuiFormControl-root': { width: 500 },
                        }}>
                          <FormControl>
                            <TextField
                              id="outlined-multiline-flexible"

                              multiline
                              maxRows={10}


                              variant="outlined"
                              placeholder="Any reason"

                              onChange={(event) => {

                                this.setState({
                                  removeReason: event.target.value,
                                  RemoveReasonErrortext: ""
                                })

                              }}
                            />

                            <FormHelperText className="error-o">{this.state.RemoveReasonErrortext}</FormHelperText>

                          </FormControl>
                        </Box>

                      </DialogContent>
                      <DialogActions>
                        <Button className="primary-text uppercase" onClick={this.onChangeOfRemoveReason}>RemoveAccount</Button>
                        <Button className="primary-text uppercase" onClick={this.handleClose} autoFocus>
                          cancel
                        </Button>
                      </DialogActions>
                    </Dialog> */}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <div style={{ marginBottom: "10px" }}>
                <Card className="space-3">
                  <div className="space-padding-top-20 space-padding-bottom-20">
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6} md={6}>
                        <div className="space-left-20">
                          <Typography
                            variant="h6"
                            component="p"
                            className="primary-text uppercase"
                          >
                            MY MEMBERSHIP
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={6} sm={6} md={6}>
                        {/* <div className="fl-right" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                          <Button variant="contained" color="primary" className=" float-right primary-bg color-white text-center1" style={{ bottom: '30px' }} onClick={this.updatepackage} >Update Package</Button>
                        </div> */}
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={6} sm={6} md={6}>
                        <div style={{ marginLeft: "20px" }}>
                          <Typography variant="small">
                            <b>
                              {" "}
                              {activePackageData &&
                                activePackageData.membertiername
                                ? activePackageData.membertiername
                                : "You are using Free Plan"}
                            </b>
                          </Typography>
                        </div>
                      </Grid>
                      {/* <Grid item xs={6} sm={6} md={6}>
                        <div>
                          <Typography variant="small" className="float-right">$ {activePackageData && activePackageData.price ? activePackageData.price : '00'}/Month</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <div style={{ marginLeft: '10px' }}>
                          <Typography variant="p" align="center" className="text-wrap1 padding-1">
                            {activePackageData && activePackageData.shortdesc ? activePackageData.shortdesc : 'Up to 10 Searches per month'}

                          </Typography>
                        </div>
                      </Grid> */}
                    </Grid>
                    {/* <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={12} className="padding-0">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB"
                              className="float-right"

                            />
                          }
                          label="Add Listing and Promotions"
                        />
                      </Grid>
                    </Grid> */}
                  </div>
                </Card>
              </div>
              <br />

              <Paper className="space-3" style={{ height: "auto" }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submitForm1(e);
                  }}
                >
                  <div className=" space-left-20">
                    <Typography
                      variant="h6"
                      component="p"
                      className="primary-text uppercase"
                    >
                      BUSINESS INFORMATION
                    </Typography>
                    {isActionAllowed(
                      this.state.accessData,
                      "edit_business_info"
                    ) &&
                      (this.state.disbleeditbtn1 ? (
                        <Button
                          variant="contained"
                          color="primary"
                          className=" float-right primary-bg color-white text-center1 form-width-20"
                          style={{ bottom: "30px" }}
                          onClick={this.handleSubmit1}
                        >
                          EDIT
                        </Button>
                      ) : (
                        ""
                      ))}
                  </div>
                  <div className="space-2">
                    <Grid container spacing={1}>
                      {!this.state.isDisabled1 && (
                        <Grid
                          item
                          xs={9}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "30px",
                            paddingBottom: "15px",
                          }}
                        >
                          <input
                            accept="image/*"
                            className="input-upload"
                            id="image"
                            name="files"
                            type="file"
                            onChange={(e) => this.uploadLogo(e)}
                          />
                          <label htmlFor="image">
                            <div
                              className="primary-border bg-color-F5F5F5"
                              style={{
                                boxShadow: "0 10px 6px -6px #777",
                                width: "200px",
                                marginBottom: "30px",
                              }}
                            >
                              <div
                                className="space-5 text-center1"
                                style={{ width: "100%" }}
                              >
                                <div className="text-center1">
                                  {/* <img src={AddIcon} alt="Addicon" style={{cursor:'pointer'}}/> */}
                                  <Typography variant="body2">
                                    Upload Company Logo
                                  </Typography>
                                </div>
                              </div>
                            </div>
                          </label>
                          <Cropper
                            ref={this.cropperRef}
                            src={this.state.src}
                            style={{ height: 300, width: 300 }}
                            aspectRatio={1}
                            guides={false}
                            crop={this.cropImage}
                            // viewMode={1}
                            // minCropBoxHeight={20}
                            // minCropBoxWidth={20}
                            background={false}
                            responsive={true}
                          // autoCropArea={2}
                          // zoomTo={0.2}
                          />
                          <button
                            onClick={this.cropImage}
                            style={{ display: "none" }}
                          >
                            Crop Image
                          </button>
                          {!this.state.isLogoValid && (
                            <FormHelperText className="error-o">
                              {this.state.onUploadLogoErrorText}
                            </FormHelperText>
                          )}
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={9}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          textAlign: "center",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="form-group multi-preview"
                          style={{ display: "block" }}
                        >
                          {this.state.isDisabled1 && (
                            <div
                              className="space-marign-bottom-10"
                              style={{ textAlign: "center" }}
                            >
                              <label className="myprofile-font">
                                Company Logo
                              </label>
                            </div>
                          )}
                          {this.state.uploadLogo && (
                            <img
                              src={
                                this.state.uploadLogo?.includes('"')
                                  ? this.state.uploadLogo.replaceAll('"', "")
                                  : this.state.uploadLogo
                              }
                              alt="Business logo"
                              width="200px"
                            />
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Business Name:
                          </label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={this.state.isDisabled}
                            value={this.state.businessName}
                            onChange={this.onChangeOfBusinessName}
                            //  value={userInfoData && userInfoData.businessname}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onBusinessNameErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">Bank Name:</label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.bankName}
                            onChange={this.onChangeOfbankName}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onBankNameErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Account Name:
                          </label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.accountName}
                            onChange={this.onChangeOfaccountName}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onAccountNameErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Account Number:
                          </label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.accountNumber}
                            onChange={this.onChangeOfaccountNumber}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onAccountNumberErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">ACH Routing:</label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.achRouting}
                            onChange={this.onChangeOfachRouting}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onAchRoutingErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Wire Routing:
                          </label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.wireRouting}
                            onChange={this.onChangeOfwireRouting}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onWireRoutingErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">Swift Code:</label>
                          <TextField
                            id="standard-password-input"
                            required
                            disabled={this.state.isDisabled1}
                            value={this.state.swiftCode}
                            onChange={this.onChangeOfswiftCode}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onSwiftCodeErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">Country:</label>
                          <Select
                            style={{ marginTop: 0 }}
                            value={this.state.country}
                            disabled={this.state.isDisabled1}
                            onChange={this.onChangeOfCountry}
                          >
                            {this.state.countryListData &&
                              this.state.countryListData.map((Country) => (
                                <MenuItem value={Country.id}>
                                  {Country.name}
                                </MenuItem>
                              ))}
                          </Select>
                          <FormHelperText className="error-o">
                            {this.state.onCountryErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Address Line1:
                          </label>
                          <TextField
                            id="standard-password-input"
                            disabled={this.state.isDisabled1}
                            value={this.state.addressLine1}
                            onChange={this.onChangeOfAddressLine1}
                            //  value={userInfoData && userInfoData.addressline1}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onAddressLine1ErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Address Line2:
                          </label>
                          <TextField
                            id="standard-password-input"
                            disabled={this.state.isDisabled1}
                            value={this.state.addressLine2}
                            onChange={this.onChangeOfAddressLine2}
                            //  value={userInfoData && userInfoData.addressline2}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          {/* <FormHelperText className="error-o">{this.state.onAddressLine2ErrorText}</FormHelperText> */}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">City:</label>
                          <TextField
                            id="standard-password-input"
                            disabled={this.state.isDisabled1}
                            value={this.state.city}
                            onChange={this.onChangeOfCity}
                            //  value={userInfoData && userInfoData.city}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onCityErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">State:</label>
                          <Select
                            style={{ marginTop: 0 }}
                            value={
                              this.state.statename
                                ? Number(this.state.statename)
                                : ""
                            }
                            disabled={this.state.isDisabled1}
                            onChange={this.onChangeOfState}
                          >
                            {this.state.statesListData &&
                              this.state.statesListData.map((item) => {
                                return (
                                  <MenuItem value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <FormHelperText className="error-o">
                            {this.state.onStateErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">Zip Code:</label>
                          <TextField
                            id="standard-password-input"
                            disabled={this.state.isDisabled1}
                            value={this.state.zipCode}
                            onChange={this.onChangeOfZipCode}
                            inputProps={{ maxLength: 6 }}
                            //  value={userInfoData && userInfoData.zip}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onZipCodeErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <div onClick={this.props.handleNext}>
                          {this.state.disbledntn1 ? (
                            <Button
                              type="submit"
                              className="SubmitColor space-marign-20"
                              disabled={this.state.isDisabled1}
                              fullWidth={false}
                              onClick={this.validateInput1}
                            >
                              Update
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </form>
              </Paper>
              <br />
              <Paper className="space-3" style={{ height: "auto" }}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    this.submitForm(e);
                  }}
                >
                  <div className=" space-left-20">
                    <Typography
                      variant="h6"
                      component="p"
                      className="primary-text uppercase"
                    >
                      PROFILE INFORMATION
                    </Typography>
                    {this.state.disbleeditbtn ? (
                      <Button
                        variant="contained"
                        color="primary"
                        className=" float-right primary-bg color-white text-center1 form-width-20"
                        style={{ bottom: "30px" }}
                        onClick={this.handleSubmit}
                      >
                        EDIT
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="space-2">
                    {/* {(user)} */}
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-b-20 margin-p-b-20">
                          <label className="myprofile-font">First Name:</label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={this.state.isDisabled}
                            value={this.state.firstName}
                            onChange={this.onChangeOfFirstName}
                            //  value={userInfoData && userInfoData.firstname}
                            type="text"
                            className="registerSpace"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onFirstNameErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-b-10 margin-p-b-20">
                          <label className="myprofile-font">Last Name:</label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={this.state.isDisabled}
                            value={this.state.lastName}
                            onChange={this.onChangeOfLastName}
                            //  value={userInfoData && userInfoData.lastname}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onLastNameErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Country Code:
                          </label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={this.state.isDisabled}
                            value={this.state.countryCode}
                            onChange={this.onChangeOfCountryCode}
                            inputProps={{ maxLength: 4 }}
                            //  value={userInfoData && userInfoData.countrycode}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />

                          <FormHelperText className="error-o">
                            {this.state.onCountryCodeErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">
                            Mobile Number:
                          </label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={this.state.isDisabled}
                            value={this.state.phoneNumber}
                            onChange={this.onChangeOfPhoneNumber}
                            inputProps={{ maxLength: 10 }}
                            //  value={userInfoData && userInfoData.phone}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onPhoneNumberErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                          <label className="myprofile-font">Email:</label>
                          <TextField
                            required
                            id="standard-password-input"
                            disabled={true}
                            value={this.state.emailId}
                            onChange={this.onChangeOfEmailId}
                            //  value={userInfoData && userInfoData.email}
                            type="text"
                            className="registerSpace"
                            aria-describedby="component-error-text"
                          />
                          <FormHelperText className="error-o">
                            {this.state.onEmailIdErrorText}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* <Grid item xs={12} sm={12} md={6}>
                      <FormControl className="form-width-100 margin-t-b-20 margin-p-b-20">
                        <label className="myprofile-font">Gender:</label>
                        <Select style={{ marginTop: 0 }}
                          value={this.state.gender}
                          disabled={this.state.isDisabled}
                          onChange={this.onChangeOfGender}
                        >
                          <MenuItem value="Male" >Male</MenuItem>
                          <MenuItem value="Female">Female</MenuItem>
                        </Select>
                        <FormHelperText className="error-o">{this.state.onGenderErrorText}</FormHelperText>
                      </FormControl>
                       </Grid> */}

                      <Grid item xs={12} sm={12} md={6}>
                        <div onClick={this.props.handleNext}>
                          {this.state.disbledntn ? (
                            <Button
                              type="submit"
                              className="SubmitColor space-marign-20"
                              disabled={this.state.isDisabled}
                              fullWidth={false}
                              onClick={this.validateInput}
                            >
                              Update
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    <div></div>
                  </div>
                </form>
              </Paper>
            </Grid>
          </Grid>
        )}
        {/* </Loader> */}

        {/* </Container>
                </div> */}
      </div>
    );
  }
  render() {
    return (
      <div>
        <ContentWrapper
          contentView={this.renderProfileDetails()}
        ></ContentWrapper>
      </div>
    );
  }
}

function mapStateToProps({ InventoryList, dealer, auth }) {
  return {
    countryListInfo: InventoryList.countryList,
    userInfo: InventoryList.userInfo,
    activePackagesListInfo: dealer.activePackagesListInfo,
    currentUser: auth.currentUser,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      countriesList,
      getuser,
      activePackageList,
      getBusinessInfo,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileComponent);