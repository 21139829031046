import React from "react";
import { Route, Switch, Router } from "react-router-dom";
import IdleTimer from "react-idle-timer";

import PrivateRoute from "./PrivateRoute";
import constants from "./utils/constants";
import BrowseWatchesComponent from "./components/pages/browse-watches/components/browserWatchComponent";
import DealerComponent from "./components/pages/dealer/components/dealerComponent";
import DealerChooseSubscriptionsComponent from "./components/pages/dealer/components/dealerChooseSubscriptionsComponent";
import DealerBusinessDetailsComponent from "./components/pages/dealer/components/dealerBusinessDetailsComponent";
import DealerSearchProductComponent from "./components/pages/dealer/components/dealerSearchProductComponent";
import DealerProductDetailsComponent from "./components/pages/dealer/components/dealerProductDetailsComponent";
import DealerProductMainPageComponent from "./components/pages/dealer/components/dealerProductMainPageComponent";
import DealerAddNewProductDetailComponent from "./components/pages/dealer/components/dealerAddNewProductDetailComponent";
import DealerAddInventorySuccessFullComponent from "./components/pages/dealer/components/dealerAddInventorySuccessfullComponent";
import ContactUsComponent from "./components/pages/contactus/components/contactUspageComponent";
import ProductingListingComponent from "./components/pages/dealer/components/productingListingComponent";
import DealerPayment from "./components/pages/dealer/components/dealerPayment";
import DealerSubscriptionSuccessfull from "./components/pages/dealer/components/dealerSubscriptionSuccessfull";
import DealerSubscriptionThankYou from "./components/pages/dealer/components/dealerSubscriptionThankYou";
// import HorizontalLabelPositionBelowStepper from './components/pages/dealer/components/dealerStepperComponent';
import ProfileComponent from "./components/pages/dashboard/component/profileComponent";
import inventoryComponent from "./components/pages/dashboard/component/inventoryComponent";
import DealerInventoryComponent from "./components/pages/dashboard/component/dealerInventoryComponent";
import DealerInventoryDetailComponent from "./components/pages/dashboard/component/dealerInventoryDetailComponent";
import DashboardPageComponent from "./components/pages/dashboard/component/dashboardPageComonent";
import ProductAddSuccessFullComponent from "./components/pages/dealer/components/addWatchSuccessFullComponent";
import InventoryDetailComponent from "./components/pages/dashboard/component/inventoryDetailComponent";
import UpdateInventoryDetailComponent from "./components/pages/dashboard/component/updateInventeryComponent";
import dealerProductUploadImagesComponent from "./components/pages/dealer/components/dealerProductUploadImagesComponent";
import ProductDetailsComponent from "./components/pages/dashboard/component/ProductDetailsComponent";
import CreateInvoiceComponent from "./components/pages/dashboard/component/createInvoiceComponent";
import CreateInvoiceMiscComponent from "./components/pages/dashboard/component/createInvoiceMiscComponent";
import UpdatePriceComponent from "./components/pages/dashboard/component/updatePriceComponent";
import UpdateInvoiceComponent from "./components/pages/dashboard/component/updateInvoiceComponent";
import CreateInventoryPage from "./components/pages/dealer/components/createInventoryPage";
import MiscWatchesMainPage from "./components/pages/dealer/components/miscWatchesMainPage";
import MiscProductMainPage from "./components/pages/dealer/components/miscProductMainPage";
import AdminPageComponent from "./components/pages/administration/adminPageComponent";
import ActivityLogsPageComponent from "./components/pages/administration/activityLogsPageComponent";
import ViewInvoice from "./components/pages/dashboard/component/viewInvoice";
import InvoiceComponent from "./components/pages/dashboard/component/invoiceComponent";
import ProfilePageComponent from "./components/pages/dashboard/component/profileComponent";
import BulkUploadComponent from "./components/pages/dashboard/component/bulkUploadComponent";
import MiscBucketBulkUploadComponent from "./components/pages/dashboard/component/miscBucketBulkUpload";
import MiscProductBulkUploadComponent from "./components/pages/dashboard/component/miscProductBulkUpload";

import InventoryComponent from "./components/pages/dashboard/component/inventoryComponent";
import stepperComponent from "./components/pages/dealer/components/stepperComponent";
import UpgradePlan from "./components/pages/common/components/upgradePlan";
import privateDealerInventory from "./components/pages/dashboard/component/DealerFeed/privateDealerInventory";
import DealerInventoryMainPage from "./components/pages/dealer-inventory/components/dealerInventoryMainPage";
import DealerDetailedView from "./components/pages/dealer-inventory/components/dealerDetailedView";
import dealerInformation from "./components/pages/dealer-inventory/components/dealerInformation";
import DealerInventoryView from "./components/pages/dealer-inventory/components/dealerInventoryView";

const userDetails = window.sessionStorage.getItem("userDetails")
  ? JSON.parse(window.sessionStorage.getItem("userDetails"))
  : null; // 10 hours
const USER_INACTIVE_TIME = userDetails
  ? userDetails.userInActiveTime
  : constants.USER_INACTIVE_TIME;
const Status = function ({ code, children }) {
  return (
    <Router
      render={function ({ staticContext }) {
        if (staticContext) {
          staticContext.status = code;
        }
        return children;
      }}
    />
  );
};

const NotFound = function () {
  return (
    // <Status code={404}>
    <div>
      <h2> Sorry, can’t find this page</h2>
    </div>
    // </Status>
  );
};

class Layout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeout: USER_INACTIVE_TIME, // 12 hours
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
    };

    this.idleTimer = null;
    this.onAction = this._onAction.bind(this);
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  _onAction(e) {
    if (this.state.isTimedOut === true) {
      this.setState({ isTimedOut: false });
    }
  }

  _onActive(e) {
    if (this.state.isTimedOut === true) {
      this.setState({ isTimedOut: false });
    }
  }

  _onIdle(e) {
    console.log("USER_INACTIVE_TIME", USER_INACTIVE_TIME);
    console.log("user is idle", new Date());
    this.idleTimer.reset();
    localStorage.removeItem("UserBusinessDetails");
    localStorage.removeItem("UserId");
    window.sessionStorage.removeItem("userDetails");
    localStorage.removeItem("token");
    window.sessionStorage.removeItem("sortByValue");
    window.sessionStorage.removeItem("dealerFilter");
    window.sessionStorage.removeItem("dealerFilterKey");
    window.sessionStorage.removeItem("dealerFilterDial");
    window.sessionStorage.removeItem("dealerName");
    window.location.href = "/login";
    // }
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  handleLogout() {
    this.setState({ showModal: false });
    this.props.history.push("/");
  }

  render() {
    const { match } = this.props;
    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          crossTab={{
            type: "localStorage", // default undefined (tries broadcastMessage then localStorage)
            emitOnAllTabs: true, // Emit events on all tabs/windows of a browser. Default false
          }}
          timeout={this.state.timeout}
        />
        <Switch>
          <PrivateRoute
            exact={true}
            path={`${match.path}search`}
            component={BrowseWatchesComponent}
            restrict={[]}
          />

          <PrivateRoute
            exact={true}
            path={`${match.path}browsewatches/:productId`}
            component={BrowseWatchesComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}browsewatches/:productId/:productName`}
            component={BrowseWatchesComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}product-listing`}
            component={ProductingListingComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}product-listing/:productId`}
            component={ProductingListingComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer-product`}
            component={DealerPayment}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}choose-subcription`}
            component={DealerChooseSubscriptionsComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer-subscription-success`}
            component={DealerSubscriptionSuccessfull}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer-subscription-thank-you`}
            component={DealerSubscriptionThankYou}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer`}
            component={DealerProductMainPageComponent}
            restrict={[11]}
          />

          <PrivateRoute
            exact={true}
            path={`${match.path}create-inventory`}
            component={CreateInventoryPage}
            restrict={[11]}
          />

          <PrivateRoute
            exact={true}
            path={`${match.path}misc-watch`}
            component={MiscWatchesMainPage}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}misc-product`}
            component={MiscProductMainPage}
            restrict={[11]}
          />

          <PrivateRoute
            exact={true}
            path={`${match.path}dealer/:productId`}
            component={DealerProductMainPageComponent}
            restrict={[11]}
          />
          <Route
            exact={true}
            path={`${match.path}dealerbusinessdetails`}
            component={DealerBusinessDetailsComponent}
            restrict={[]}
          />
          {/* 
          Comment on Jan 25
          <Route
            exact={true}
            path={`${match.path}dealersubscribtions`}
            component={stepperComponent}
            restrict={[]}
          /> */}
          <PrivateRoute
            exact={true}
            path={`${match.path}inventory`}
            component={inventoryComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer-inventory`}
            component={DealerInventoryComponent}
            restrict={[11]}
          />

          <PrivateRoute
            exact={true}
            path={`${match.path}myinventoryold/:bulkUploadId`}
            component={DashboardPageComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}updateproduct`}
            component={ProductDetailsComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}updateprice`}
            component={UpdatePriceComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}create/invoice`}
            component={CreateInvoiceComponent}
            restrict={[12]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}create/invoice/misc`}
            component={CreateInvoiceMiscComponent}
            restrict={[12]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}view/invoice`}
            component={ViewInvoice}
            restrict={[12]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}edit/invoice/:invoiceId`}
            component={UpdateInvoiceComponent}
            restrict={[12]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory`}
            component={DealerProductMainPageComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory-new-watch-details`}
            component={DealerProductDetailsComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory-new-product-details`}
            component={DealerAddNewProductDetailComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory-search`}
            component={DealerSearchProductComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory-successful`}
            component={DealerAddInventorySuccessFullComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-inventory-successful/:inventoryid/:userId`}
            component={DealerAddInventorySuccessFullComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}add-to-product-successful`}
            component={ProductAddSuccessFullComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}update-inventry-details/:inventryId`}
            component={UpdateInventoryDetailComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}update-inventry-images-details/:inventryId`}
            component={dealerProductUploadImagesComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}administration`}
            component={AdminPageComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}administration`}
            component={ActivityLogsPageComponent}
            restrict={[]}
          />
          <Route
            exact={true}
            path="/contactus"
            component={ContactUsComponent}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}myinventoryold`}
            component={DashboardPageComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path="/profile"
            component={ProfileComponent}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dashboard`}
            component={DashboardPageComponent}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}inventory-detail/:id`}
            component={InventoryDetailComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}inventory-detail/:id/:userId`}
            component={InventoryDetailComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}dealer-inventory-detail/:id`}
            component={DealerInventoryDetailComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}invoices`}
            component={InvoiceComponent}
            restrict={[12]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}myprofile`}
            component={ProfilePageComponent}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}bulk-upload`}
            component={BulkUploadComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}misc-bucket-bulk-upload`}
            component={MiscBucketBulkUploadComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}misc-product-bulk-upload`}
            component={MiscProductBulkUploadComponent}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path={`${match.path}myinventory`}
            component={InventoryComponent}
            restrict={[11]}
          />
          <PrivateRoute
            exact={true}
            path="/privatedealerfeed"
            component={privateDealerInventory}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path="/dealerinventory"
            component={DealerInventoryMainPage}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path="/dealerinventory/view"
            component={DealerDetailedView}
            restrict={[]}
          />
          <PrivateRoute
            exact={true}
            path="/dealerinventory/info"
            component={dealerInformation}
            restrict={[]}
          />
          <Route
            exact={true}
            path="/dealerinventory/inventory-view/:inventoryId?"
            component={DealerInventoryView}
            restrict={[]}
          />
          <Route
            exact={true}
            path={`${match.path}not-found`}
            component={UpgradePlan}
            restrict={[]}
          />
        </Switch>
      </>
    );
  }
}

export default Layout;
