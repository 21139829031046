import * as React from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    IconButton,
    Box,
    Typography,
    Modal,
    Select,
    MenuItem,
    InputLabel,
    FormHelperText,
    Input,
} from '@mui/material';
import SuccessImage from "../../../../assests/images/success.png"
import { useForm, Controller } from 'react-hook-form';
import { inviteUser } from '../actions/administration';
import { toast } from 'react-toastify';
import { useState } from 'react';


const InviteUserModal = ({ open, handleModalClose, saveModal, saveModalOpen }) => {

    const { register, handleSubmit, formState: { errors }, reset, control } = useForm();
    const [loading, setLoading] = useState(false);

    const onSubmit = (data) => {
        setLoading(true)
        const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null
        const inputObject = {
            first_name : data.FirstName,
            last_name   : data.LastName,
            accountid: userDetails ? userDetails.accountId : null,
            email: data.Email,
            roleid: data.Role
        }
        console.log(inputObject, "data");

        inviteUser(inputObject,(error,result)=>{
            console.log("invite user response")
            setLoading(false)
            if(result && result.success){
                saveModalOpen(true);
            }else{
                toast(result?.message || "Error in sending invite to user", {
                    autoClose: 5000,
                    type: toast.TYPE.ERROR,
                    position: toast.POSITION.BOTTOM_CENTER,
                  });
            }
        })
        
    
    }
    return (
        <div>

            <Modal
                open={open}
                onClose={() => {
                    reset({
                        FirstName1: '',
                        LastName1: '',
                        Role1: '',
                        Email1: '',
                    })
                      handleModalClose()
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '30%',
                    height: 'auto',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'background.paper',
                    border: '0px solid #000',

                }}
                >

                    <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', borderBottom: '1px solid #e0e0e0', paddingBottom: '15px', alignItems: 'center' }}>
                        <Grid>
                            <Typography variant="h6" component="h6" style={{ marginLeft: '10px', marginTop: '10px' }}>Invite User</Typography>
                        </Grid>
                        <Grid >
                            <IconButton onClick={()=>{
                                reset({
                                    FirstName1: '',
                                    LastName1: '',
                                    Role1: '',
                                    Email1: '',
                                })
                                handleModalClose()}} >
                                <ClearIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Grid style={{ display: 'flex', padding: '20px 35px 5px 35px' }}>
                        <form onSubmit={handleSubmit(onSubmit)}>

                            <FormControl variant="standard" className='formControl' style={{ marginBottom: '10px' }}  >
                                <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}> First Name  </InputLabel>
                                <Input id="component-simple"
                                    style={{ marginBottom: '8px' }}
                                    {...register("FirstName", { required: 'Enter a First Name' })}
                                />
                                {errors.FirstName?.type === "required" && (
                                    <FormHelperText className="error-o">{errors.FirstName.message}</FormHelperText>
                                )}
                            </FormControl>

                            <FormControl variant="standard" className='formControl'>
                                <InputLabel style={{ color: '#424242' }}>Last Name  </InputLabel>
                                <Input id="component-simple" style={{ marginBottom: '10px' }}
                                    {...register("LastName", { required: 'Please Enter The Last Name' })}
                                />
                                {errors.LastName?.type === "required" && (
                                    <FormHelperText className="error-o">{errors.LastName.message}</FormHelperText>
                                )}
                            </FormControl>

                            <Controller
                                name="Role"
                                control={control}
                                rules={{ required: 'Please Select The Role' }}
                                defaultValue=""
                                render={({ field }) => (
                                    <FormControl variant="standard" className='formControl'>
                                        <InputLabel id="demo-simple-select-standard-label">Role</InputLabel>
                                        <Select
                                            className='select-input'
                                            style={{ color: "black", marginBottom: '10px' }}
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label="Role"
                                            {...field}
                                        >
                                            <MenuItem value="2">Administrator</MenuItem>
                                            <MenuItem value="7">Back Office Viewer</MenuItem>
                                            <MenuItem value="8">Sales Personnel Viewer</MenuItem>
                                            <MenuItem value="9">Data Entry Assistant</MenuItem>

                                        </Select>
                                        {errors.Role && (
                                            <FormHelperText className="error-o">{errors.Role.message}</FormHelperText>
                                        )}
                                    </FormControl>
                                )}
                            />
                            <FormControl variant="standard" className='formControl' style={{ marginBottom: '10px' }} >
                                <InputLabel htmlFor="component-simple" style={{ color: '#424242' }}>Email  </InputLabel>
                                <Input id="component-simple"
                                    {...register("Email", {
                                        required: "Please Enter The Email",
                                        pattern: /\S+@\S+\.\S+/,
                                    })}
                                />
                                {errors.Email?.type === "required" && (
                                    <FormHelperText className="error-o">{errors.Email.message}</FormHelperText>
                                )}
                                {errors.Email?.type === "pattern" && (
                                    <FormHelperText className="error-o">Invalid email address</FormHelperText>
                                )}
                            </FormControl>

                            <Box style={{
                                paddingTop: '1px', paddingLeft: '2px', paddingRight: '1px', paddingBottom: '4px'
                            }}
                            >

                                <Grid style={{ display: 'flex', flexDirection: 'row', marginTop: '35px', justifyContent: 'flex-end' }}>
                                    <Grid>
                                        <Button variant="text" style={{ width: '20px', color: 'blue', marginRight: '40px' }} onClick={() => { 
                                        reset({
                                    FirstName1: '',
                                    LastName1: '',
                                    Role1: '',
                                    Email1: '',
                                })
                                handleModalClose()}}  >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid>
                                        <Button 
                                        variant="text" 
                                        type='submit' 
                                        disabled={loading}
                                        style={{ width: '20px', color: loading ? '#ccc' : 'blue' }}  >
                                            Save
                                        </Button>

                                    </Grid>

                                </Grid>
                            </Box>

                        </form>
                    </Grid>

                </Box>
            </Modal>
            {saveModal ?
                <Modal
                    open={saveModal}
                    onClose={() => {
                        reset({
                            FirstName1: '',
                            LastName1: '',
                            Role1: '',
                            Email1: '',
                        })
                          handleModalClose()
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '40%',
                        height: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '0px solid #000',

                    }}
                    >

                        <Grid style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', float: 'right' }}>
                            <Grid>
                                <IconButton onClick={() => {
                                          reset({
                                           FirstName1: '',
                                           LastName1: '',
                                           Role1: '',
                                           Email1: '',
                                              })
                                        handleModalClose()
                                           }} >
                                    <ClearIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '85px' }}>
                            <Grid >
                                <img src={SuccessImage} alt='SuccessImage' style={{ width: '120px', height: '110px' }}></img>
                            </Grid>
                            <Grid>
                                <Typography variant="h6" component="h2">Invite Sent Successfully</Typography>
                            </Grid>
                        </Grid>

                    </Box>
                </Modal>

                : null}
        </div>
    );
}

export default InviteUserModal;