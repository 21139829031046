import constants from "../../../../utils/constants";
const initialState = {
    inventoryDetailByIdInfo: {
        data: [],
    },
    invoicePriceInfo: {
        data: [],
    },
    dashboardaccordionInfo: {
        data: [],
    },
    brandInfo: {
        data: []
    },
    BrandInfoByBrandName: {
        data: []
    },
    InventoryList: {
        data: [],
    },
    InventoryDetailsList: {
        data: [],
    },
    inventoryXlIdInfo: {
        data: []
    },
    monthlyListInfo: {
        data: [],
    },
    weeklyListInfo: {
        data: [],
    },
    dailyListInfo: {
        data: [],
    },
    invetoryCountInfo: {
        data: [],
    },
    countryList: [],
    statesListInfo: {
        data: [],
    },
    userInfo: [],
    inventrywatches: [],
    modelList: {
        data: [],
    },
    inventoryid: {
        data: [],
    },
    brandModelList: {
        data: [],
    },
    billShipCntctLst: {
        data: [],
    },
    BulkUploadInfo: {},
    invoiceDetails: {},
    isError: false,
    isLoading: false
};

export default (state = initialState, action) => {
    let currentState = state;
    switch (action.type) {
        case 'FETCH_STATESLIST_DATA_SUCCESS':
            return {
                ...state,
                statesListInfo: action.payload
            };
            break;
        case 'FETCH_INVENTORY_WATCHES_DATA_SUCCESS':
            currentState = {
                ...state,
                InventoryList: action.payload
            };
            break;
        case 'FETCH_INV_DEALER_WATCHES_DATA_SUCCESS':
            currentState = {
                ...state,
                InventoryDetailsList: action.payload
            };
            break;
        case 'FETCH_INVOICE_PRICE_DATA_SUCCESS':
            currentState = {
                ...state,
                invoicePriceInfo: action.payload
            };
            break;
        case 'FETCH_DASHBOARD_ACCORDION_DATA_SUCCESS':
            currentState = {
                ...state,
                dashboardaccordionInfo: action.payload
            };
            break;
        case 'FETCH_BROWSE_WATCHES_BRAND_DATA_SUCCESS':
            currentState = {
                ...state,
                brandInfo: action.payload
            };
            break;
        case 'FETCH_BROWSE_WATCHES_BRAND_NAME_BY_BRAND_DATA_SUCCESS':
            currentState = {
                ...state,
                BrandInfoByBrandName: action.payload
            };
            break;
        case 'FETCH_INVENTORY_DETAIL':
            currentState = {
                ...state,
                inventoryDetailByIdInfo: action.payload
            };
            break;
        case 'FETCH_INVENTORY_XL_DATA_SUCCESS':
            currentState = {
                ...state,
                inventoryXlIdInfo: action.payload
            };
            break;
        case 'FETCH_MONTHLY_DATA_SUCCESS':
            currentState = {
                ...state,
                monthlyListInfo: action.payload
            };
            break;
        case 'FETCH_WEEKLY_DATA_SUCCESS':
            currentState = {
                ...state,
                weeklyListInfo: action.payload
            };
            break;
        case 'FETCH_DAILY_DATA_SUCCESS':
            currentState = {
                ...state,
                dailyListInfo: action.payload
            };
            break;
        case 'FETCH_INVENTORY_COUNT_DATA_SUCCESS':
            currentState = {
                ...state,
                invetoryCountInfo: action.payload
            };
            break;
        case 'FETCH_COUNTRYLIST_DATA_SUCCESS':
            currentState = {
                ...state,
                countryList: action.payload
            };
            break;
        case 'FETCH_USERINFO_DATA_SUCCESS':
            let currentUser = {
                userId: action.payload.data.userid,
                email: action.payload.data.email,
                firstName: action.payload.data.firstname,
                lastName: action.payload.data.lastname,
                roleId: action.payload.data.roleid,
                profilePic: action.payload.data.profilepic,
                isPlanExpired: action.payload.data.isPlanExpired,
                roleAccess: action.payload.data.access,
                accountName: action.payload.data.businessname,
                accountId: action.payload.data.accountid,
                membertierId: action.payload.data.membertier_id,
                dealerInventoryExpiryDate: action.payload.data.dealerinventory_expirydate,
                dealerInventorySubscription: action.payload.data.dealerinventory_plan,
                isDealerInventoryExpired: action.payload.data.IsDealarInventoryExipred,
                planDetails: action.payload.data.plandetails,

            };
            // window.sessionStorage.setItem('userDetails', JSON.stringify(currentUser));
            currentState = {
                ...state,
                userInfo: action.payload
            };
            break;
        case 'FETCH_BROWSE_WATCHES_INVENTRY_DATA_SUCCESS':

            currentState = {
                ...state,
                inventrywatches: action.payload
            };
            break;
        case 'BULK_UPLOAD_SUCCESS':
            return {
                ...state,
                BulkUploadInfo: action.payload
            };
            break;
        case 'CREATE_INVOICE_REQUESTED':
            return { ...state, isLoading: true };

        case 'CREATE_INVOICE_SUCCESS':
            return { ...state, isLoading: false, invoiceDetails: action.payload };

        case 'CREATE_INVOICE_FAILURE':
            return { ...state, isLoading: false, isError: true };



        case 'FETCH_INVENTORY_LIST':
            return { ...state, isLoading: true, error: null };

        case 'FETCH_INVENTORY_SUCCESS':
            return { ...state, inventoryidList: action.payload, isLoading: false, isError: false };

        case 'FETCH_INVENTORY_FAILURE':
            return { isLoading: false, inventoryidList: action.payload, isLoading: false, isError: true };




        case 'FETCH_MODEL_LIST':
            return { ...state, model: { ...state.model, isLoading: true } };

        case 'FETCH_MODEL_SUCCESS':
            return { ...state, modelList: action.payload, isLoading: false, isError: false };

        case 'FETCH_MODEL_FAILURE':
            return { isLoading: false, modelList: action.payload, isLoading: false, isError: true };

        case 'FETCH_BRANDMODEL_LIST':
            return { ...state, model: { ...state.model, isLoading: true } };

        case 'FETCH_BRANDMODEL_SUCCESS':
            return { ...state, brandModelList: action.payload, isLoading: false, isError: false };

        case 'FETCH_BRANDMODEL_FAILURE':
            return { isLoading: false, brandModelList: action.payload, isLoading: false, isError: true };

        case 'FETCH_BILLSHIP_CNTCT_LIST':
            return { ...state, billShipCntctLst: { ...state.billShipCntctLst, isLoading: true } };

        case 'FETCH_BILLSHIP_CNTCT_SUCCESS':
            return { ...state, billShipCntctLst: action.payload, isLoading: false, isError: false };

        case 'FETCH_BILLSHIP_CNTCT_FAILURE':
            return { isLoading: false, billShipCntctLst: action.payload, isLoading: false, isError: true };

        default:
    }
    return currentState;
}
