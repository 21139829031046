import axios from 'axios';
import constants from './../../../../utils/constants';
import { getToken } from "./../../../../utils/Authentication";
const fileDownload = require('js-file-download');
export function getUsersList(data, callback) {
    return function (dispatch) {
        dispatch({ type: "ADMIN_DASHBORDS_LIST" });
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        axios
            .post(constants.BASE_URL + `accounts/usermanage/dashboard`, data, { headers })
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "ADMIN_DASHBORDS_LIST_SUCCESS",
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
};

export function getUserTrackingList(data, callback) {
    return function (dispatch) {
        dispatch({ type: "USER_TRACKING_LIST" });
        const headers = {
            'Authorization': `Bearer ${getToken() || ''}`,
            'Content-Type': 'application/json'
        };
        const config = {
            method: 'post',
            url: constants.BASE_URL + `accounts/userslist`,
            headers: headers,
            data: data
        };
        axios(config)
            .then((response) => {
                if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                    localStorage.removeItem('token');
                    window.sessionStorage.removeItem("userDetails");
                    localStorage.removeItem("UserBusinessDetails");
                    window.location.href = '/login';
                } else {
                    dispatch({
                        type: "USER_TRACKING_LIST_SUCCESS",
                        payload: response.data.data,
                    });
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
};
export function getUserTrackingDownload(payload) {

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `accounts/download/userlist`,
        headers: headers,
        data: payload,
        responseType: 'arraybuffer',
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                const blob = new Blob([response.data]
                    , {
                        type:
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
                    })
                fileDownload(blob, 'tracking.xlsx')
                const data = {
                    status: 1,
                    message: "Downloaded Successfully",

                }
                return data
            }
        })
        .catch((error) => {
            const data = {
                status: 0,
                message: "Downloaded Failed",
            }
            return data
        });

};

export async function inviteUser(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + 'users/inviteuser',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function updateInviteUserDetails(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + 'accounts/updateinviteuser',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function updateUserRole(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + 'accounts/updaterole',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function removeUser(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + 'accounts/removeuser',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function ActivateUser(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + 'accounts/update/userstatus',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function resendInvitation(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + 'accounts/resenduser',
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function getDealerFeedSetting(callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'get',
        url: constants.BASE_URL + `dealerfeeds/accountlist/${userDetails?.accountId}`,
        headers: headers,
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function getInventorySetting(callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'get',
        url: constants.BASE_URL + `inventorysetting/accountlist/${userDetails?.accountId}`,
        headers: headers,
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function createDelaerFeedSetting(data, callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `dealerfeeds/create`,
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function createInventorySetting(data, callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `inventorysetting/create`,
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function updateDealerFeedSetting(data, callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + `dealerfeeds/update`,
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};

export async function updateInventorySetting(data, callback) {

    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;

    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'put',
        url: constants.BASE_URL + `inventorysetting/update`,
        headers: headers,
        data: data
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            return callback(error, null)
        })
};


export async function getAllAcountList(callback) {


    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'get',
        url: constants.BASE_URL + `accountsplan/list`,
        headers: headers,
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            //   return callback(error, null )
        })
};

export async function getAccountBasedList(accountId, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'get',
        url: constants.BASE_URL + `accountsplan/list/${accountId}`,
        headers: headers,
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            //   return callback(error, null )
        })
};

export async function updateSubscriptionDetails(payload, callback) {


    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    const config = {
        method: 'post',
        url: constants.BASE_URL + `accountsplan/create`,
        headers: headers,
        data: payload
    };
    return axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data)
            }
        })
        .catch((error) => {
            console.log(error)
            //   return callback(error, null )
        })
};

export function dealerCommunicationTackingList(data, callback) {
    const headers = {
        'Authorization': `Bearer ${getToken() || ''}`,
        'Content-Type': 'application/json'
    };
    var config = {
        method: 'post',
        url: constants.BASE_URL + `dealerinventory/communications/list`,
        headers: headers,
        data: data
    };
    axios(config)
        .then((response) => {
            if (response && response.data && response.data.success === 0 && response.data.logout === true) {
                localStorage.removeItem('token');
                window.sessionStorage.removeItem("userDetails");
                localStorage.removeItem("UserBusinessDetails");
                window.location.href = '/login';
            } else {
                return callback(null, response.data);
            }
        }).catch((error) => {
            return callback(error, null);
        })
};

