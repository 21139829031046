import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { isLoggedIn } from "./../../../utils/Authentication";
import constants from "../../../utils/constants";
const initialState = {
    loading: false,
    currentUser: null,
    countryList: [],
    statesListInfo: {
        data: [],
    },
    recoveryPasswordInfo: [],
    verifyOtpInfo: [],
    forgotPasswordChange: [],
    userDetailsInfo: [],
    userRegisteredData: []
};
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case "LOGIN_REQUESTED":
            return {
                ...state,
                loading: true,
            };
        case "LOGIN_SUCCESS":
            localStorage.setItem("token", action.payload.data.token);
            let currentUser = {
                userId: action.payload.data.userid,
                email: action.payload.data.email,
                firstName: action.payload.data.firstname,
                lastName: action.payload.data.lastname,
                roleId: action.payload.data.roleid,
                profilePic: action.payload.data.profilepic,
                isPlanExpired: action.payload.data.isPlanExpired,
                roleAccess: action.payload.data.access,
                accountName: action.payload.data.businessname,
                accountId: action.payload.data.accountid,
                membertierId: action.payload.data.membertier_id,
                userInActiveTime: action.payload.userInActiveTime ? Number(action.payload.userInActiveTime) : constants.USER_INACTIVE_TIME,
                dealerInventoryExpiryDate: action.payload.data.dealerinventory_expirydate,
                dealerInventorySubscription: action.payload.data.dealerinventory_plan,
                isDealerInventoryExpired: action.payload.data.IsDealarInventoryExipred,
                planDetails: action.payload.data.plandetails,
            };
            window.sessionStorage.setItem('userDetails', JSON.stringify(currentUser));
            return {
                ...state,
                loading: false,
                currentUser: currentUser,
            };
        case "LOGIN_FAILED":
            localStorage.removeItem("token");
            window.sessionStorage.removeItem('userDetails');
            return {
                ...state,
                loading: false,
                currentUser: null,
            };



        case "LOGOUT":
            localStorage.removeItem('token');
            window.sessionStorage.removeItem("userDetails");
            // localStorage.removeItem("UserBusinessDetails");

            return {
                ...state,
                loading: false,
                currentUser: null,
            };
        case "TOKEN_VERIFIED":
            return {
                ...state,
                currentUser: action.payload
            };

        case "SIGNUP_REQUESTED":
            return {
                ...state,
                loading: true,
            };
        case "SIGNUP_SUCCESS":
            localStorage.setItem("token", action.payload.data.token);
            return {
                ...state,
                loading: false,
            };
        case "SIGNUP_FAILED":
            return {
                ...state,
                loading: true,
            };
        case 'FETCH_COUNTRYLIST_DATA_SUCCESS':
            return {
                ...state,
                countryList: action.payload
            };
        case 'FETCH_STATESLIST_DATA_SUCCESS':
            return {
                ...state,
                statesListInfo: action.payload
            };
        case 'FETCH_RECOVERPASSWORD_DATA_SUCCESS':
            return {
                ...state,
                recoveryPasswordInfo: action.payload
            };
        case 'FETCH_VERIFYOTP_DATA_SUCCESS':
            return {
                ...state,
                verifyOtpInfo: action.payload
            };
        case 'FETCH_FORGOT_PASSWORD_DATA_SUCCESS':
            return {
                ...state,
                forgotPasswordChange: action.payload
            };

        case 'GET_USER_DETAILS_DATA_SUCCESS':
            return {
                ...state,
                userDetailsInfo: action.payload
            };
        case 'USER_REGISTERED_SUCCESS':
            return {
                ...state,
                userRegisteredData: action.payload
            };

        default:
            return state;
    }
}