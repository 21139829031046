import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentWrapper from '../../../../common/components/contentWrapper';
import { Container, Paper, Typography, Grid, InputLabel, Input, Button, FormControl, TextField, FormHelperText, Link } from '@material-ui/core';
import validator from 'validator';
import '../../../../common/style/main.css';
import { toast } from "react-toastify";
import { verifyOtp, login, addUserData, recoverPassword } from "./../../reducer/actions";

class OtpComponent extends Component {
  constructor(props) {
    
    super(props);
    this.state = {
      countryCode: '',
      emailId: '',
      phoneNumber: '',
      otp: '',
      showing: true,
      onOtphange: '',
      emailerrormsg: "",
      otpErrorText: '',
      isValid: false,
      disabled: true,
      country_code: true,
      phonenumber: true,
      showOtpBtn: false,
      showSmsbtn: false,
      emailOTP: '',
      mobileOTP: '',
      otpId: '',

    }
    // this.onOtphange = this.onOtphange.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.verifyEmailOtp = this.verifyEmailOtp.bind(this);
    this.onPhoneNumberChange = this.onPhoneNumberChange.bind(this);
  }
  componentDidMount(){
    console.log("componentDidMount")
    console.log(this.props.userDetailsInfo)
  }
  emailShow = () => {
    this.setState({ showing: true });
    this.state.phoneNumber = '';
    this.state.countryCode = '';
    this.state.phoneNumberFlag = '';
    this.setState({
      emailFlag: 'email',
    })
    this.sendEmailOtp();
  }
  mobileShow = () => {
    this.setState({ showing: false });
    this.state.emailId = '';
    this.state.emailFlag = '';
    this.setState({
      phoneNumberFlag: 'phone',
    })
    this.sendSmsOtp();
  }
  sendEmailOtp = () => {
    
    this.setState({ disabled: !this.state.disabled })
    this.state.showOtpBtn = true;
    const email = localStorage.getItem('email') ? localStorage.getItem('email') : this.props.userDetailsInfo.email;
    let otpData = {
      'to':  email,
      'url': 'email-otp/send'
    };
    console.log("data",otpData)
    this.props.recoverPassword(otpData, (error, res) => {
      if (error) {
        toast("Somthing went wrong", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        toast("OTP is sent to your registered Email Id" + " " + email, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  }
  verifyEmailOtp = () => {
    console.log(this.state.emailOTP, "abc")
    if (this.state.emailOTP != '') {

      let otpData = {
        'otp': this.state.emailOTP,
        'to': localStorage.getItem('email')
      };
      this.props.verifyOtp(otpData, (error, res) => {
        console.log(error, res, "result")
        if (res?.success === true) {
          toast("Otp verified successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          this.props.history.push("/login");
        } else {
          toast("Entered wrong OTP", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          })
          //todo uncomment
          this.props.history.push("/login");
        }
      })
    }else {
      this.setState({
        ...this.state,
        emailerrormsg: "Enter OTP",
        isValid: true
      })
    }
  }
  onEmailIdChange = (event) => {
    let otpemail = validator.isNumeric(event.target.value);
    console.log(otpemail)
    if (!otpemail) {
      this.setState({
        ...this.state,
        emailerrormsg: "Enter Valid OTP",
        isValid: false

      })
      console.log(this.state.emailerrormsg)
    } else {
      this.setState({
        ...this.state,
        emailerrormsg: "",
        isValid: true
      })
      console.log(this.state.emailerrormsg)

    }



    // if (!otpemail) {
    //   this.setState({
    //     ...this.state,
    //     otpErrorText: "Enter Otp",
    //     isValid: false
    //   });
    // } else {
    //   this.setState({
    //     ...this.state,
    //     otpErrorText: "",
    //     isValid: true
    //   });
    // }





    console.log(event.target.value);
    this.setState({
      emailOTP: event.target.value
    })
    console.log(this.state.emailOTP, "emailOtp")
  }
  sendSmsOtp = () => {
    console.log("data")
    this.setState({ country_code: !this.state.country_code })
    this.setState({ phonenumber: !this.state.phonenumber })
    this.state.showSmsbtn = true;
    const phoneNumber = localStorage.getItem('mobile') ? localStorage.getItem('mobile') : this.props.userDetailsInfo.phone
    let otpData = {
      'phone': '8056807597',
      'country_code': localStorage.getItem('country_code') ? localStorage.getItem('country_code') : this.props.userDetailsInfo.country_code
    };

    this.props.recoverPassword(otpData, (error, res) => {
      this.setState({
        otpId: res.otp_id
      })
      if (error) {
        toast("Somthing went wrong", {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      } else {
        // history.push("/add-to-inventory-search");
        toast("OTP is sent to your registered Phone Number" + " " + phoneNumber, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });

  }
  verifySmsOtp = () => {
    if (this.state.mobileOTP != '') {
      let otpData = {
        'otp': this.state.mobileOTP,
        // 'otp_id': localStorage.getItem('mobile')
        'otp_id': this.state.otpId
      };
      this.props.verifyOtp(otpData, (error, res) => {
        console.log(error, res, "aa");
        if (res.success == false) {
          toast("Entered wrong OTP", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        } else {
          toast("Otp verified successfully", {
            autoClose: 5000,
            type: toast.TYPE.SUCCESS,
            position: toast.POSITION.BOTTOM_CENTER,
          });
          // this.props.history.push("/register");
          this.props.history.push("/login");
          localStorage.removeItem("UserBusinessDetails");

        }
      })
    }
  }

  onPhoneNumberChange(event) {
    let otpVal = validator.isNumeric(event.target.value);
    console.log(event.target.value, "aa");
    setTimeout(() => {
      this.setState({
        ...this.state,
        mobileOTP: event.target.value,
        otp: event.target.value
      });
    }, 100);
    console.log(otpVal, this.state.mobileOTP, this.state.otp);
    if (!otpVal) {
      this.setState({
        ...this.state,
        otpErrorText: "Enter OTP",
        isValid: false
      });
    } else {
      this.setState({
        ...this.state,
        // otp: event.target.value,
        otpErrorText: "",
        isValid: true
      });
    }
  }

  // onOtphange(event) {
  //   let otpVal = validator.isNumeric(event.target.value);
  //   console.log(otpVal,"qqq");
  //   if (!otpVal) {

  //     this.setState({
  //       ...this.state,
  //       otpErrorText: "Enter Otp",
  //       isValid: false
  //     });
  //   } else {
  //     this.setState({
  //       ...this.state,
  //       otp: event.target.value,
  //       otpErrorText: "",
  //       isValid: true
  //     });
  //   }
  // }


  validateInput() {
    let otpErrorText = '';
    let otpErroremailText = '';
    let isValid = true;
    if (this.state.mobileOTP.length === 0) {
      otpErrorText = this.state.mobileOTP + "Enter OTP"
      isValid = false;
    }
    if (this.state.emailOTP.length === 0) {
      // otpErroremailText = this.state.emailOTP 
      isValid = false;
      this.setState({
        ...this.state,
        emailerrormsg: "Enter OTP",
       isValid : false

      })
    }

    this.setState({
      ...this.state,
      otpErrorText,
      otpErroremailText
    });
    return isValid;
  }

  submitForm(event) {
    const { signUpRequested, loginRequested, loginSuccess, loginFailed, history } = this.props;
    if (this.validateInput()) {
      let loginInput = {
        email: this.props.userDetailsInfo && this.props.userDetailsInfo.email,
        password: this.props.userDetailsInfo && this.props.userDetailsInfo.password,
      };
      let otpInput = {
        'otp': this.state.otp,
        'to': this.props.userDetailsInfo && this.props.userDetailsInfo.email,
        'url': 'email-otp/verify'
      };
      let signInput = {
        "first_name": this.props.userDetailsInfo && this.props.userDetailsInfo.first_name,
        "last_name": this.props.userDetailsInfo && this.props.userDetailsInfo.last_name,
        "country_code": this.props.userDetailsInfo && this.props.userDetailsInfo.country_code,
        "phone": this.props.userDetailsInfo && this.props.userDetailsInfo.phone,
        "email": this.props.userDetailsInfo && this.props.userDetailsInfo.email,
        "password": this.props.userDetailsInfo && this.props.userDetailsInfo.password,
      };
      this.props.verifyOtp(otpInput, (error, res) => {
        if (error) {
          toast("Somthing went wrong", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });

        } else {
          if (res.success == 1 || res.message == 'Token is valid.') {
            signUpRequested(null);
            addUserData(signInput, (error, res) => {
              if (res.success === 1) {
                loginRequested(null);
                login(
                  loginInput,
                  (res) => {
                    if (res.success === 1) {
                      toast("User Login successfully", {
                        autoClose: 5000,
                        type: toast.TYPE.SUCCESS,
                        position: toast.POSITION.BOTTOM_CENTER,
                      });
                      loginSuccess(res);
                      window.localStorage.setItem('newlyregisterdUser', true)
                      history.push("/add-to-inventory-search");
                    } else {
                      loginFailed(res);
                      toast(res.message, {
                        autoClose: 5000,
                        type: toast.TYPE.SUCCESS,
                        position: toast.POSITION.BOTTOM_CENTER,
                      });
                    }
                  },
                  (error) => {
                    loginFailed(error);
                    toast(res.message, {
                      autoClose: 5000,
                      type: toast.TYPE.SUCCESS,
                      position: toast.POSITION.BOTTOM_CENTER,
                    });
                  }
                );
              } else {
                toast(res.message, {
                  autoClose: 5000,
                  type: toast.TYPE.ERROR,
                  position: toast.POSITION.BOTTOM_CENTER,
                });
              }

            });
          }
          // else {
          //   toast('Invalid OTP', {
          //     autoClose: 5000,
          //     type: toast.TYPE.ERROR,
          //     position: toast.POSITION.BOTTOM_CENTER,
          //   });
          // }
        }
      })
    }
  }



  getContentView() {
    return (
      <div>
        <Container maxWidth="sm" className="space-3">
          <Paper className="border-radius">
            <div className="text-center">
              <Typography variant="h5" gutterBottom align="center" className="text-white">
                Verify OTP
</Typography>
            </div>
            <div className="text-center1">
              <Typography variant="small" className="text-center1">
                Verify OTP via
{/* <br/>you will receive an email with instructions. if you are <br/>having problems recovering your password <Link href="/" className="notVendorLink">contact</Link> */}
              </Typography>
            </div>
            <form className="padding" onSubmit={(e) => { e.preventDefault(); this.submitForm(e) }} id='recovery_password' >
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12}>
                  <div style={{ textAlign: 'center' }}>
                    <label > 
                      <input type="radio" name="tab" defaultChecked value="email" align="center" onClick={this.emailShow} /><b>EMAIL</b>
                      </label>
&nbsp;&nbsp;&nbsp; <label> 
                    <input type="radio" name="tab" value="mobile" onClick={this.mobileShow} /><b>MOBILE NUMBER</b>
                   </label>
                  </div>
                  {this.state.showing
                    ?
                    <Grid container spacing={2}>
                      <Grid item={12} sm={12} md={12} xs={12}>
                        <FormControl className="form-width-100 ">
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">OTP</InputLabel>
                          <Input id="email"
                            display="block"
                            onChange={this.onEmailIdChange}
                            defaultValue={this.state.emailId}
                          />
                          <FormHelperText className="error-o">{this.state.emailerrormsg}</FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* {this.state.showOtpBtn? */}
                      <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={this.verifyEmailOtp} >Verify OTP</Button>
                      {/* :
                       <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={this.sendEmailOtp} >send OTP</Button>
                      } */}
                      {/* <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={this.sendEmailOtp} >{this.state.showOtpBtn ? "Verify OTP" : "send OTP"}</Button> */}

                    </Grid>
                    :
                    <Grid container spacing={2}>
                      <Grid item={12} sm={4} md={4} xs={12}>
                        {/* <FormControl className="registerSpace form-width-100">
<InputLabel id="demo-simple-select-outlined-label" className="color-BCBCBC">COUNTRY CODE</InputLabel>
<Input id="phone"
display="none"
onChange={this.onChangeOfCountryCode}
disabled = {(this.state.country_code)? "disabled" : ""}
/>

<FormHelperText className="error-o">{this.state.countryCodeError}</FormHelperText>
</FormControl> */}
                      </Grid>
                      <Grid item={12} sm={12} md={12} xs={12}>
                        <FormControl className="form-width-100 margin-t-b-10 margin-p-b-10" >
                          <InputLabel htmlFor="component-simple" className="color-BCBCBC">OTP </InputLabel>
                          <Input id="phone"
                            display="none"
                            onChange={this.onPhoneNumberChange}
                            defaultValue={this.state.phoneNumber}
                          // disabled={(this.state.phonenumber) ? "disabled" : ""}
                          />
                          <FormHelperText className="error-o" >{this.state.otpErrorText}</FormHelperText>
                        </FormControl>
                      </Grid>
                      {/* {this.state.showSmsbtn ? */}
                      <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={this.verifySmsOtp} >Verify OTP</Button>
                      {/* : <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={this.sendSmsOtp} >send OTP</Button>} */}

                    </Grid>

                  }

                  {/* <Typography variant="h6" align="center" className="color-383838 space-m-1 ">
OR
</Typography>
*/}

                  {/* <FormHelperText className="error-o">{this.state.phoneemailErrorText}</FormHelperText> */}

                </Grid>
                {/* <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} >Send OTP</Button> */}
              </Grid>
            </form>
            <div className="space-1"></div>
          </Paper>
        </Container>
      </div>
    );
  }
  render() {
    console.log('this.state', this.state.localEmail, this.state.localPhone);
    console.log('props', this.props);

    return (<ContentWrapper
      contentView={this.getContentView()}
    />
    )
  }
}

function mapStateToProps({ auth }) {
  return {
    recoveryPasswordInfo: auth.recoveryPasswordInfo,
    userDetailsInfo: auth.userDetailsInfo,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    signUpRequested: (payload) => dispatch({ type: "SIGNUP_REQUESTED", payload }),
    signUpSuccess: (payload) => dispatch({ type: "SIGNUP_SUCCESS", payload }),
    signUpFailed: (payload) => dispatch({ type: "SIGNUP_FAILED", payload }),
    loginRequested: (payload) => dispatch({ type: "LOGIN_REQUESTED", payload }),
    loginSuccess: (payload) => dispatch({ type: "LOGIN_SUCCESS", payload }),
    loginFailed: (payload) => dispatch({ type: "LOGIN_FAILED", payload }),
    verifyOtp,
    recoverPassword
  },
    dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(OtpComponent);
